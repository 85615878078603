export const removeDuplicates = (targetArray) => {
  targetArray.sort((a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });

  let i = 0;
  while (i < targetArray.length) {
    if (targetArray[i] === targetArray[i + 1]) {
      targetArray.splice(i + 1, 1);
    } else {
      i += 1;
    }
  }
  return targetArray;
}

