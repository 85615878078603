import { getPlaybackURL } from '../utils/config';
import { getNaturalDimensions, log } from '../utils/embed-helpers';
import messages from '../utils/messages';
import Promise from '../utils/promise';
import { logger } from '../api/debug';

export const loadPlaceholder = (
  img: HTMLImageElement,
): Promise<HTMLImageElement | Error> => {
  if (!img) {
    return Promise.reject(new Error(messages.errors.placeholder));
  }

  const { width, height } = getNaturalDimensions(img);
  const uuid = img.getAttribute('data-uuid');

  // reject images without source or uuid
  if (img.src === '' || !uuid) {
    return Promise.reject(new Error(messages.errors.placeholder));
  }

  // reject images that are loaded and have no size
  if (img.complete && (width === 0 || height === 0)) {
    return Promise.reject(new Error(messages.errors.placeholder));
  }

  if (img.complete) {
    // image is already loaded
    return Promise.resolve(img);
  } else {
    // load the image
    return new Promise((res, rej) => {
      img.onload = () => res(img);
      img.onerror = () => rej(img);
    });
  }
};

export interface RenderOptions {
  uuid?: string;
  container?: HTMLElement;
  type?: string;
  aspect?: string | number;
  width?: string;
  height?: string;
}

export const createPlaceholder = ({
  uuid,
  container,
  type = 'inline',
  aspect = 'landscape',
  ...other
}: RenderOptions = {}) => {
  if (!uuid || !container) {
    return;
  }
  logger.setLevel('placeholder')('creating placeholder image');
  const image = document.createElement('img');
  const width = other.width ? `${other.width}px` : '100%';
  const height = other.height ? `${other.height}px` : '100%';
  const style = `
    display: block;
    margin: 0 auto;
    max-height: ${height};
    max-width: ${width};
    opacity: 0;
  `;
  image.src = `https://${getPlaybackURL()}/${uuid}.jpg`;
  image.className = 'vidyard-player-embed';
  image.setAttribute('style', style);
  image.setAttribute('data-uuid', uuid);
  image.setAttribute('data-type', type);

  // assign all of the other keys as data params on the image
  Object.keys(other).forEach((k) => image.setAttribute(`data-${k}`, other[k]));

  return container.appendChild(image);
};

export const loadServerThumbnail = (uuid): Promise<HTMLImageElement | Error> => {
  const image = document.createElement('img');
  image.setAttribute('data-uuid', uuid);
  image.src = `//${getPlaybackURL()}/${uuid}.jpg`;
  return loadPlaceholder(image);
};
