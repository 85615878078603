import { getCurrentScript } from './embed-helpers';

const dynamicPlaybackURL = (function playbackURL() {
  let playbackURLOverride;

  return {
    setPlaybackURL(url: string) {
      playbackURLOverride = url;
      return playbackURLOverride;
    },

    getPlaybackURL() {
      // override the `PLAYBACK_URL` if data-playbackurl exists on the script tag
      const currentScript = getCurrentScript();
      const stagingPlaybackUrl = currentScript
        ? currentScript.getAttribute('data-playbackurl')
        : null;

      return (
        playbackURLOverride ||
        window.VIDYARD_PLAYBACK_URL ||
        stagingPlaybackUrl ||
        'play.vidyard.com'
      );
    },
  };
})();

export * from './constants';
export const { setPlaybackURL } = dynamicPlaybackURL;
export const { getPlaybackURL } = dynamicPlaybackURL;
export const getBaseURL = () => `https://${getPlaybackURL()}/v4/`;
export const getErrorURL = () => `https://${getPlaybackURL()}/v4/error`;
