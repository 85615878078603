/*
* Handles sweeping over the DOM and injecting all types of players
* Effectively is the puppet master for all the embed code stuff
*/
import dispatchReady from '../api/dispatch-ready';
import { processReadyListeners } from '../api/add-ready-listener';
import { logger } from '../api/debug';

import injectInlineElements from '../controllers/inline-player';
import injectLightboxElements from '../controllers/lightbox-player';
import injectBacklink from '../controllers/backlink';
import setupIntegrations from '../controllers/integrations';
import setupSEO from '../controllers/seo';

import { getElementByClass, toArray, log as consoleLog, find } from '../utils/embed-helpers';
import createContainer from '../views/container';

import DataParams from './data-params';
import preloadFrames from './preload-frames';
import VidyardPlayer from './vidyard-player';

export const sweepDocument = (container = document): void => {
  // Find all divs that belong to vy
  const embedLocations = getElementByClass('vidyard-player-embed', 'img', container);
  preloadFrames(embedLocations);
  toArray(embedLocations).forEach(renderPlayer);
  dispatchReady();
};

export const renderPlayer = (placeholderImg: HTMLImageElement): VidyardPlayer => {
  const { integrations, api, players, playerReadyListeners } = window.VidyardV4;
  const dataParams = new DataParams(placeholderImg);
  const { uuid } = dataParams;
  const log = logger.setLevel(uuid);
  log(`rendering`);

  // Cant do anything without a uuid, just skip it
  if (uuid === undefined) {
    consoleLog(
      'Embed without a UUID detected, it is impossible to insert a player without a UUID. Add "data-uuid=some_uuid" to the offending element.' +
        placeholderImg,
    );
    return;
  }

  if (dataParams.rendered === 'true') {
    log(`Already rendered`);
    return find(players, (player) => player.placeholder === placeholderImg);
  }

  const container = createContainer(uuid, dataParams);
  const newPlayer = new VidyardPlayer(container, uuid);

  placeholderImg.setAttribute('data-rendered', 'true');
  placeholderImg.parentNode.insertBefore(container, placeholderImg);

  newPlayer.placeholder = placeholderImg;
  newPlayer.container = container;
  players.push(newPlayer);

  processReadyListeners(newPlayer);

  // Inject player HTML into the dom
  if (dataParams.type === 'inline') {
    injectInlineElements({ dataParams, player: newPlayer });
  } else if (dataParams.type === 'lightbox') {
    injectLightboxElements({ dataParams, player: newPlayer });
  }

  setupIntegrations(newPlayer);
  setupSEO(newPlayer.uuid).then((jsonpData) => {
    if (dataParams.type === 'inline') {
      injectBacklink(newPlayer, jsonpData, dataParams.backlink && dataParams.backlink === 1);
    }
  });

  // add the player to the window.Vidyard global
  // used for progress events and some integrations
  window.Vidyard._players[newPlayer.uuid] = newPlayer;

  return newPlayer;
};
