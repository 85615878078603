import { logger } from '../api/debug';
import { isObjectEmpty } from '../utils/embed-helpers';
import { getSEO } from '../utils/jsonp';
import { createJsonLD } from '../models/markup-injector';

export interface DetailsPayload {
  '@context'?: string;
  '@type'?: string;
  backlinkURL: string;
  description?: string;
  duration?: string;
  embedUrl?: string;
  hasBacklink: boolean;
  height?: number;
  id?: string;
  keywords?: string;
  name?: string;
  thumbnailUrl?: string;
  transcript?: string;
  uploadDate?: string;
  width?: number;
}

export default function seoController(uuid: string): Promise<DetailsPayload> {
  const log = logger.setLevel(uuid);
  log(`getSEO sent`);

  return getSEO(uuid)
    .then(
      (data: DetailsPayload): DetailsPayload => {
        log(`getSEO: ${JSON.stringify(data)}`);
        if (!isObjectEmpty(data)) {
          const jsonLD = createJsonLD(data);
          document.getElementsByTagName('head')[0].appendChild(jsonLD);
        }
        return data;
      },
    )
    .catch((e) => {
      log('getSEO failed, likely a network error');
    });
}
