import { includes, indexOfArray, log } from '../utils/embed-helpers';
import VidyardPlayer from '../models/vidyard-player';

function unsubscribeProgressEvents(player: VidyardPlayer) {
  player.progressEventsUnsubscribe.forEach((cb) =>  cb());
  player.progressEventsUnsubscribe = [];
}

export default function destroyPlayer(player) {
  const { players } = window.VidyardV4;

  if (includes(players, player)) {
    unsubscribeProgressEvents(player);
    player.off();
    player.container.parentNode.removeChild(player.container);
    player.placeholder.parentNode.removeChild(player.placeholder);
    players.splice(indexOfArray(player, players));
  } else {
    log(`Cannot destroy an unknown player`, 'warn');
  }
}
