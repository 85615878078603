/* tslint:disable variable-name */
import { VERSION } from '../utils/config';
import { dataSet, parseVyData, log, getQueryParam } from '../utils/embed-helpers';

class DataParams {
  public access_code?: string;
  public autoplay: number;
  public custom_id?: string;
  public disable_popouts = 1;
  public height?: string;
  public overlayZindex?: number;
  public rendered?: 'true';
  public type: 'inline' | 'lightbox';
  public uuid: string;
  public v: string;
  public vyemail?: string;
  public vyetoken?: string;
  public vysfid?: string;
  public width?: string;
  public playlist_always_open?: string;
  public backlink?: number;

  constructor(placeHolderImage: HTMLImageElement) {
    const data = parseVyData(dataSet(placeHolderImage));
    Object.keys(data).forEach((key) => {
      this[key] = data[key];
    });

    // Default to inline embed
    if (this.type !== 'inline' && this.type !== 'lightbox') {
      log('Invalid Vidyard player embed type, defaulting to inline.', 'warn');
      this.type = 'inline';
    }

    // Report the embed script version
    this.v = VERSION;
    // This embed code no longer supports popout CTAs
    this.disable_popouts = 1;
    // Gets the vycustomid query string param, used to identify personalized videos
    this.custom_id = this.custom_id || getQueryParam('vycustom_id');
    // Gets the vyemail query string param, which connects views to an email entered
    this.vyemail = this.vyemail || getQueryParam('vyemail');
    // Sets a fixed Vidyard Salesforce ID for every view
    this.vysfid = this.vysfid || getQueryParam('vysfid');
    // Sets a fixed Vidyard Salesforce ID for every view
    this.vyetoken = this.vyetoken || getQueryParam('vyetoken');

    // Gets & sets the access code parameter which will bypass having the user enter it manually
    const vyac = getQueryParam('vyac');
    const vyplayer = getQueryParam('vyplayer');
    if (vyac && vyplayer && vyplayer === this.uuid) {
      this.access_code = vyac;
    } else if (vyac && vyplayer) {
      log('Invalid Vidyard player access code.', 'warn');
    }
  }

  public toQueryString() {
    let queryParamsString = '?';
    let key: string;

    for (key in this) {
      if (!this.hasOwnProperty(key)) {
        continue;
      }

      // don't include these in the query string
      if (key === 'height' || key === 'uuid' || key === 'width') {
        continue;
      }

      // don't include undefined keys
      if (this[key] === undefined) {
        continue;
      }

      // Add '&' here so we don't end up with one trailing
      queryParamsString += queryParamsString !== '?' ? '&' : '';
      queryParamsString += encodeURIComponent(key) + '=' + encodeURIComponent(this[key]);
    }

    // Just return an empty string if no params were found
    return queryParamsString === '?' ? '' : queryParamsString;
  }
}

export default DataParams;
