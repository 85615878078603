import { logger } from '../api/debug';
import { checkJSONParse } from '../utils/embed-helpers';
import { getMarketoLead, getIntegrations } from '../utils/jsonp';
import VidyardPlayer from '../models/vidyard-player';

export default function integrationsControler(player: VidyardPlayer): void {
  const { integrations } = window.VidyardV4;
  const log = logger.setLevel(player.uuid);
  const marketoCookie = integrations.getCookie('marketo');

  if (marketoCookie) {
    log(`getMarketoLead sent`);
    getMarketoLead(player.uuid, marketoCookie)
      .then(checkJSONParse)
      // @TODO: add better types here
      .then(
        (leadData: any): void => {
          log(`getMarketoLead received: ${leadData}`);
          player.on(
            'ready',
            (): void => {
              integrations.addKnownVisitor('marketo', leadData, player.org);
            },
          );
        },
      )
      .catch(
        (): void => {
          logger.warn('Invalid Marketo cookie');
        },
      );
  }

  // Get info on what our integrations are by hitting /v4/:uuid/integrations.js
  log(`getIntegrations`);
  // @TODO: add better types here
  getIntegrations(player.uuid).then(
    (data: any): void => {
      log(`getIntegrations received: ${JSON.stringify(data)}`);
      player.on('ready', (): void => {
        player._tmpOrg = data;
        integrations.updatePlayer(player);
      });
    },
  ).catch(e => {
    log('getIntegrations failed, likely a network error');
  });
}
