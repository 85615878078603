import { createEvent, once } from '../utils/embed-helpers';

// Allows the client to listen for the Vidyard API to become available
// Example:
// window.VidyardV4
//   ? initApp(window.VidyardV4)
//   : (window.onVidyardAPI = (vyApi) => initApp(vyApi));
//
// Or with promises:
// new Promise(res => window.VidyardV4
//   ? res(window.VidyardV4)
//   : (window['onVidyardAPI'] = (vyApi) => res(vyApi))
// ).then((vyApi) => {
//   console.log('api is ready ', vyApi);
// });
const readyCallback = () => {
  if (window.onVidyardAPI && typeof window.onVidyardAPI === 'function') {
    window.onVidyardAPI(window.VidyardV4);
  }
};

// We also dispatch a custom event on document indicating that the API is ready
// Example:
// window.VidyardV4
//   ? initApp(window.VidyardV4)
//   : document.addEventListener('onVidyardAPI', ({ detail: vyApi }) => initApp(vyApi));
const readyEvent = () => {
  document.dispatchEvent(createEvent('onVidyardAPI', window.VidyardV4));
};

const dispatchReady = once(() => {
  readyCallback();
  readyEvent();
});

export default dispatchReady;
