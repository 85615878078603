/*
* Adds link rel prefetch for the iframes
*/
import { getPlaybackURL } from '../utils/config';
import * as EmbedHelpers from '../utils/embed-helpers';
import DataParams from './data-params';

export default function preloadFrames(
  embeds = EmbedHelpers.getElementByClass('vidyard-player-embed', 'img'),
): void {
  for (const ele of embeds) {
    const dataParams = new DataParams(ele);
    const { uuid } = dataParams;
    const preloadHref = `https://${getPlaybackURL()}/${uuid}${dataParams.toQueryString()}`;

    if (!uuid) {
      continue;
    }

    if (EmbedHelpers.indexOfArray(preloadHref, window.VidyardV4.preloadLinks) === -1) {
      addPrefetchLink(uuid, preloadHref);
      window.VidyardV4.preloadLinks.push(preloadHref);
    }
  }
}

// --- Private Functions ---
function addPrefetchLink(uuid: string, href: string): string {
  const preloadLink = document.createElement('link');

  preloadLink.rel = 'prefetch';
  preloadLink.crossOrigin = 'anonymous';
  preloadLink.href = href;

  document.body.appendChild(preloadLink);
  return href;
}
