import { ERROR_ORIGIN_RE, getErrorURL } from './config';
import { addListener, log, xhrRequest } from './embed-helpers';
import createErrorReport from './error-report';

type errorHandler = (
  options: {
    error: Error;
    filename: string;
    message: string;
    timeStamp: number;
    type: string;
  },
) => void;

const vyError = (source: string = '') => source.match(ERROR_ORIGIN_RE);

export const errorHandler: errorHandler = ({ error, filename, message, timeStamp, type }) => {
  if (vyError(filename)) {
    const { location, navigator, VidyardV4: vyGlobal } = window;

    const payload = createErrorReport({
      error,
      location,
      message,
      navigator,
      timeStamp,
      type,
      vyGlobal,
    });

    xhrRequest({ endpoint: getErrorURL(), method: 'POST', payload }).then(
      (res) => log(`Error logged ${res}`),
      (e) => log(`Error log failed ${e}`),
    );
  }
};

export default function logErrors() {
  addListener('error', 'onerror', errorHandler, window);

  addListener('unhandledrejection', '', (e: PromiseRejectionEvent) => {
    const { reason: error, timeStamp, type } = e;

    if (e.reason instanceof Error && vyError(error.stack)) {
      // prevent errors in the console
      e.preventDefault();

      errorHandler({
        error,
        // PromiseRejectionEvents do not have a filename field
        // the workaround is to look for the v4.js script in the stack trace
        filename: error.stack,
        message: error.message,
        timeStamp,
        type,
      });
    }
  });
}
