/*
* Applies and controls styles relating to centering the lightbox within the parent window
*/
import { OVERLAY_FADE_TIME } from '../utils/config';
import * as EmbedHelpers from '../utils/embed-helpers';
import { ariaHideChildElements, undoAriaHideChildElements } from '../utils/accessibility-helpers';
import userAgentChecker from '../utils/user-agent-checker';
import focusLock from 'dom-focus-lock';
import screenreaderTrap from 'makeup-screenreader-trap';

interface ContainerElements {
  backerElement: HTMLDivElement;
  containerElement: HTMLDivElement;
  containingDiv: HTMLDivElement;
  iframe: HTMLIFrameElement;
}

interface LightboxElements {
  closeContainer: HTMLDivElement;
  contentFixed: HTMLDivElement;
  overlay: HTMLDivElement;
  overlayWrapper: HTMLDivElement;
  popbox: HTMLDivElement;
}

export function show(
  iframeString: string,
  thumbnailContainer: HTMLDivElement,
  overlayZindex: number,
): { container: ContainerElements, lightbox: LightboxElements } {
  const container = generateLightboxContainer(iframeString);
  const lightbox = generateLightboxHTML(overlayZindex);
  const lightboxConstraint = generateConstraintImage(thumbnailContainer);

  // Needed so that the lightbox isn't the last element in the <body> to
  // allow focusLock to listen for a focus event when focus leaves the
  // last element in the lightbox
  const focusableElement = document.createElement('div');
  focusableElement.id = 'vidyard-focusable-element';
  focusableElement.tabIndex = 0;

  lightbox.popbox.appendChild(container.backerElement);
  lightbox.popbox.appendChild(container.containingDiv);
  lightbox.popbox.appendChild(lightboxConstraint);

  if (userAgentChecker.ios()) {
    ariaHideChildElements();
  }

  // Add them to the DOM
  document.body.appendChild(lightbox.overlayWrapper);
  document.body.appendChild(focusableElement);

  lightbox.closeContainer.focus();
  focusLock.on(lightbox.overlayWrapper);
  screenreaderTrap.trap(lightbox.overlayWrapper);

  // Have to use a timeout so css will actually transition the opacity
  setTimeout(function animateOpacity(): void {
    lightbox.overlayWrapper.style.opacity = '1';
    lightbox.overlayWrapper.style.filter = 'alpha(opacity=100)';
  }, 0);

  return {
    container,
    lightbox,
  };
}

export function remove(callbacks?): void {
  const fixedElement = document.getElementById('vidyard-content-fixed');
  const focusableElement = document.getElementById('vidyard-focusable-element');
  const overlay = document.getElementById('vidyard-overlay');
  const overlayWrapper = document.getElementById('vidyard-overlay-wrapper');
  const popbox = document.getElementById('vidyard-popbox');

  if (!fixedElement || !overlay || !overlayWrapper || !popbox) {
    return;
  }

  if (callbacks) {
    Object.keys(callbacks).forEach((k): void => {
      const cb = callbacks[k];
      cb();
    });
  }

  if (focusableElement) {
    focusableElement.parentNode.removeChild(focusableElement);
  }

  focusLock.off(overlayWrapper);
  screenreaderTrap.untrap(overlayWrapper);

  overlayWrapper.style.opacity = '0';
  overlayWrapper.style.filter = 'alpha(opacity=0)';

  const cleanup = (): void => {
    overlayWrapper.parentNode.removeChild(overlayWrapper);
  };

  if (userAgentChecker.ios()) {
    undoAriaHideChildElements();
  }

  // Clean up the added DOM elements once fade out is complete
  setTimeout(cleanup, OVERLAY_FADE_TIME * 1000);
}

export function makeIframeVisible(iframe: HTMLIFrameElement): void {
  iframe.style.opacity = '1';
}

// --- Private Functions ---
function generateLightboxHTML(overlayZindex: number): LightboxElements {
  // Create all the elements for the overlay
  const overlay = document.createElement('div');
  const contentFixed = document.createElement('div');
  const popbox = document.createElement('div');
  const overlayWrapper = document.createElement('div');
  const closeContainer = generateCloseButton().closeContainer;

  overlay.id = 'vidyard-overlay';
  overlay.setAttribute('aria-hidden', 'true');
  overlay.style.display = 'block';

  contentFixed.id = 'vidyard-content-fixed';
  contentFixed.setAttribute('aria-label', 'media player lightbox');
  contentFixed.setAttribute('role', 'dialog');
  contentFixed.style.display = 'block';

  popbox.id = 'vidyard-popbox';
  overlayWrapper.id = 'vidyard-overlay-wrapper';
  overlayWrapper.style.display = 'block';

  contentFixed.appendChild(popbox);
  overlayWrapper.appendChild(overlay);
  overlayWrapper.appendChild(closeContainer);
  overlayWrapper.appendChild(contentFixed);

  if (overlayZindex) {
    overlay.style.zIndex = '' + overlayZindex;
    contentFixed.style.zIndex = '' + (overlayZindex + 2);
    closeContainer.style.zIndex = '' + (overlayZindex + 1);
  }

  return {
    closeContainer,
    contentFixed,
    overlay,
    overlayWrapper,
    popbox,
  };
}

function generateCloseButton(): { closeButton: HTMLDivElement, closeContainer: HTMLDivElement } {
  const closeContainer = document.createElement('div');
  const closeButton = document.createElement('div');

  closeContainer.className = 'vidyard-close-container';
  closeContainer.setAttribute('aria-label', 'Close Player');
  closeContainer.setAttribute('role', 'button');
  closeContainer.setAttribute('tabindex', '0');

  closeButton.className = 'vidyard-close-x';

  // Fix for ie7 not supporting :before pseudo elements
  // Fix for ie8 not supporting transform rotate
  if ((document as any).documentMode < 9) {
    closeButton.className += ' simple-close';
    closeButton.innerHTML = '&times;';
  }

  closeContainer.appendChild(closeButton);

  return { closeButton, closeContainer };
}

function generateLightboxContainer(playerIframeDOMString: string): ContainerElements {
  const backerElement = document.createElement('div');
  backerElement.className = 'vidyard-lightbox-content-backer';

  const containerElement = document.createElement('div');
  // hydrate the markup string into actual DOM
  containerElement.innerHTML = playerIframeDOMString;
  const containingDiv = containerElement.getElementsByTagName('div')[0];

  containingDiv.style.position = 'absolute';
  containingDiv.style.height = '100%';
  containingDiv.style.width = '100%';
  containingDiv.style.zIndex = '2';

  const iframe = containerElement.getElementsByTagName('iframe')[0];
  const iframeParent = iframe.parentNode as HTMLElement;
  // Lightbox specific styling for the iframe
  iframeParent.style.position = 'static';
  // This overwrites the padding bottom set by createIframe
  iframeParent.style.paddingBottom = '0';
  iframe.style.opacity = '1';

  // This is to ensure the background actually animates
  setTimeout(() => {
    backerElement.style.opacity = '1';
    backerElement.style.filter = 'alpha(opacity=100)';
  }, 0);

  return {
    backerElement,
    containerElement,
    containingDiv,
    iframe,
  };
}

function generateConstraintImage(container: HTMLDivElement): HTMLImageElement {
  const image = EmbedHelpers.getElementByClass(
    'vidyard-lightbox-image',
    'img',
    container,
  )[0].cloneNode();

  image.className = '';
  image.id = 'vidyard-popbox-constraint';
  image.alt = '';
  image.setAttribute('aria-hidden', 'true');

  return image;
}
