import Player from '../models/vidyard-player';

export interface Vidyard {
  _players: { [uuid: string]: Player };
  players: () => { [uuid: string]: Player };
}

const vidyardGlobal: Vidyard = {
  _players: {},
  players: () =>
    window.VidyardV4.players.reduce((acc, player) => {
      acc[player.uuid] = player;
      return acc;
    }, {}),
};

export default vidyardGlobal;
