import { logger } from '../api/debug';

// cb: callback when a player is ready, uuid: optional filter to only listen for individual player uuids
export default function addReadyListener(cb: () => void, uuid?: string) {
  const {
    VidyardV4: { playerReadyListeners },
  } = window;

  // Store all callbacks for any yet to be created players
  playerReadyListeners[uuid] = playerReadyListeners[uuid] || [];
  playerReadyListeners[uuid].push(cb);

  // Add ready listeners to all currently available players
  for (const currentPlayer of window.VidyardV4.players) {
    // Is this the correct player OR are they setting up listeners for every player
    if (uuid !== undefined && currentPlayer.uuid !== uuid) {
      continue;
    }

    currentPlayer.on('ready', cb);
  }
}

export function processReadyListeners(player) {
  const { playerReadyListeners } = window.VidyardV4;
  const { uuid } = player;
  const log = logger.setLevel(player.uuid);

  // Ensure the cb set for this uuid is defined
  playerReadyListeners[uuid] = playerReadyListeners[uuid] || [];

  // Get the total set of callbacks for this uuid && all uuids
  const callbacks = playerReadyListeners[uuid].concat(playerReadyListeners[undefined]);

  // Loop through all cbs and add them to the players listeners
  Object.keys(callbacks).forEach((k) => {
    const callback = callbacks[k];
    player.on('ready', callback);
    log(`attaching ready callbacks`);
  });
}
