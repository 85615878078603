import DataParams from '../models/data-params';

const createContainer = (uuid: string, dataParams: DataParams): HTMLDivElement => {
  const container = document.createElement('div');
  container.className = 'vidyard-player-container';
  container.setAttribute('uuid', uuid);

  const styles = container.style;
  styles.margin = 'auto';
  styles.width = '100%';
  styles.height = 'auto';
  styles.overflow = 'hidden';
  styles.display = 'block';

  if (dataParams.type === 'inline') {
    styles.maxWidth = dataParams.width ? dataParams.width + 'px' : '';
    styles.maxHeight = dataParams.height ? dataParams.height + 'px' : '';
  }

  return container;
};

export default createContainer;
