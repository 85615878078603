/*
* File contains the minimum amount of helper functions to allow embed to work in IE9+
* Has no access to jQuery / Underscore etc
*/

import Promise from './promise';

export const addListener = (
  eventName,
  oldIeEventName,
  handler,
  element: HTMLElement | Window | Document = window,
) => {
  if (window.addEventListener) {
    element.addEventListener(eventName, handler, false);
  } else if (window.attachEvent) {
    element.attachEvent(oldIeEventName, handler);
  }

  return { eventName, handler, element };
};

export const removeListener = (
  eventName,
  handler,
  element: HTMLElement | Window | Document = window,
) => {
  if (element.removeEventListener) {
    element.removeEventListener(eventName, handler, false);
  } else if (element.detachEvent) {
    element.detachEvent('on' + eventName, handler);
  } else {
    element['on' + eventName] = null;
  }
};

export const createEvent = (eventName, payload) => {
  if (typeof window.CustomEvent === 'function') {
    return new window.CustomEvent(eventName, { detail: payload });
  } else if (typeof document.createEvent === 'function') {
    const legacyCustomEvent = document.createEvent('CustomEvent');
    legacyCustomEvent.initCustomEvent(eventName, false, false, payload);
    return legacyCustomEvent;
  }
};

export const isObjectEmpty = (obj) => {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
};

export const indexOfArray = (item, arr) => {
  if (arr.indexOf) {
    return arr.indexOf(item);
  }

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === item) {
      return i;
    }
  }

  return -1;
};

export const shallowMerge = (a, b) => {
  for (const key in b) {
    if (b.hasOwnProperty(key)) {
      a[key] = b[key];
    }
  }
  return a;
};

// TODO: change to getElementsByClass
export const getElementByClass = (
  className,
  tagName = '*',
  topLevelNode: HTMLElement | Document = document,
) => {
  if (typeof document.getElementsByClassName === 'function') {
    return topLevelNode.getElementsByClassName(className);
  }

  const results = [];
  const re = new RegExp('(^| )' + className + '( |$)');
  const nodes = toArray(topLevelNode.getElementsByTagName(tagName));

  for (const node of nodes) {
    if (re.test(node.className)) {
      results.push(node);
    }
  }

  return results;
};

export const calcAspectRatio = (imageEl, additionalWidth = 0): string => {
  const size = getNaturalDimensions(imageEl);

  // fallback to landscape aspect ratio if the image does not have proper size
  return size.height !== 0 || size.width !== 0
    ? ((size.height / (size.width + additionalWidth)) * 100).toFixed(2)
    : ((9 / 16) * 100).toFixed(2); // landscape 16:9 aspect ratio, equals to 56.25%
};

export const log = (message, logType = 'log') => {
  if (window.console && typeof window.console[logType] === 'function') {
    console[logType](message);
  }
};

export const find = (array, test) => {
  for (const item of array) {
    const res = test(item);
    if (res === true) {
      return item;
    }
  }
};

export const includes = (array, itemToCheck) => find(array, (item) => item === itemToCheck);

export const once = (callback) => {
  let expired = false;

  return (...args) => {
    if (expired) {
      return;
    }

    if (callback) {
      callback(...args);
    }

    expired = true;
  };
};

export const spaceOrEnterKeyPressEvent = (callback) => (e) => {
  // spacebar or enter
  if (e.keyCode === 32 || e.keyCode === 13) {
    callback(e);
  }
};

export const escKeyPressEvent = (callback) => (e) => {
  // esc
  if (e.keyCode === 27) {
    callback(e);
  }
};

export const getCurrentScript = () =>
  // need to check for currentScript because it does not exist in IE
  document.currentScript
    ? document.currentScript
    : find(
        toArray(document.getElementsByTagName('script')),
        (script) =>
          script.src &&
          (script.src.match('vidyard-embed-code.js') !== null ||
            script.src.match(/v4(\.umd)?\.js/) !== null),
      );

export const getNaturalDimensions = (ele) => {
  if (ele.naturalWidth) {
    return { width: ele.naturalWidth, height: ele.naturalHeight };
  }

  const img = new Image();
  img.src = ele.src;
  return { width: img.width, height: img.height };
};

export const isArray = (arg) => Object.prototype.toString.call(arg) === '[object Array]';

export const toArray = (htmlCollection) => Array.prototype.slice.call(htmlCollection);

export const checkJSONParse = (jsonString) =>
  new Promise((res, rej) => {
    try {
      res(JSON.parse(jsonString));
    } catch (err) {
      rej(err);
    }
  });

export const xhrRequest = ({ endpoint, payload = {}, method = 'GET' }) =>
  new Promise((res, rej) => {
    // IE 8/9 needs to send CORS requests over XDomainRequest
    const isXDomain = new XMLHttpRequest().withCredentials === undefined && XDomainRequest;

    const xhr = isXDomain ? new XDomainRequest() : new XMLHttpRequest();
    xhr.open(method, endpoint);

    if (xhr instanceof XMLHttpRequest) {
      xhr.setRequestHeader('Content-Type', 'text/plain');
    }

    xhr.onerror = (e) => rej(e);
    xhr.onload = () => {
      if (xhr instanceof XMLHttpRequest) {
        if (Math.floor(xhr.status / 100) === 2) {
          // 200 range
          res(xhr.responseText);
        } else {
          rej();
        }
      } else {
        // XDomainRequest does not have status
        res(xhr.responseText);
      }
    };

    xhr.send(JSON.stringify(payload));
  });

export const getFullscreenAPI = () => {
  const apiMap = [
    // Spec: https://dvcs.w3.org/hg/fullscreen/raw-file/tip/Overview.html
    ['requestFullscreen', 'exitFullscreen', 'fullscreenElement', 'fullscreenchange'],
    // WebKit
    [
      'webkitRequestFullscreen',
      'webkitExitFullscreen',
      'webkitFullscreenElement',
      'webkitfullscreenchange',
    ],
    // Mozilla
    ['mozRequestFullScreen', 'mozCancelFullScreen', 'mozFullScreenElement', 'mozfullscreenchange'],
    // Microsoft
    ['msRequestFullscreen', 'msExitFullscreen', 'msFullscreenElement', 'MSFullscreenChange'],
  ];

  let browserAPI = [];
  const fullscreenAPI = {};

  for (const browserMethods of apiMap) {
    // check for exitFullscreen function
    if (browserMethods[1] in document) {
      browserAPI = browserMethods;
      break;
    }
  }

  // map the browser API names to the spec API names
  if (browserAPI && browserAPI.length) {
    for (let i = 0; i < browserAPI.length; i++) {
      fullscreenAPI[apiMap[0][i]] = browserAPI[i];
    }
    return fullscreenAPI;
  } else {
    return null;
  }
};

export const dataSet = (el: HTMLElement) => {
  const collection = {};
  const parseAttributeName = (attr: Attr) => attr.name.replace('data-', '');

  // can't do for-of here as el.attributes is not an array
  // tslint:disable-next-line
  for (let i = 0; i < el.attributes.length; i += 1) {
    const attr = el.attributes[i];

    if (attr.name.indexOf('data-') < 0) {
      continue;
    }

    collection[parseAttributeName(attr)] = attr.value;
  }

  return collection;
};

export const getQueryParam = (name: string): string | undefined => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regexString = '[\\?&]' + name + '=([^&#]*)';
  const regex = new RegExp(regexString);
  const results = regex.exec(window.location.search);

  if (results !== null) {
    return results[1];
  }
};

const parseNestedData = (encodedData: string, type: string): { [key: string]: string } => {
  try {
    const nestedData = JSON.parse(decodeURIComponent(encodedData));
    return Object.keys(nestedData).reduce((acc, key) => {
      acc[`${type}[${key}]`] = nestedData[key];
      return acc;
    }, {});
  } catch (e) {
    log(`Invalid ${type} payload`, 'warn');
    return {};
  }
};

export const parseVyData = (data: { [key: string]: string }) =>
  Object.keys(data).reduce((acc, key) => {
    if (key === 'vydata') {
      const parsedData = parseNestedData(data[key], 'vydata');
      Object.keys(parsedData).forEach((parsedKey: string) => {
        acc[parsedKey] = parsedData[parsedKey];
      });
    } else {
      acc[key] = data[key];
    }
    return acc;
  }, {});

// https://stackoverflow.com/questions/6787383/how-to-add-remove-a-class-in-javascript
export const toggleClass = (
    element: HTMLElement,
    classToToggle: string,
    forceBoolean: boolean
): void => {
  const hasClass = (ele: HTMLElement, targetClass: string): boolean => {
    return ele.className.indexOf(targetClass) !== -1;
  };

  const addClass = (ele: HTMLElement, targetClass: string): void => {
    if (!hasClass(ele, targetClass)) {
      ele.className = `${ele.className.trim()} ${targetClass}`;
    }
  };

  const removeClass = (ele: HTMLElement, targetClass: string): void => {
    if (hasClass(ele, targetClass)) {
      ele.className = ele.className.replace(targetClass, ' ').trim();
    }
  };

  if (forceBoolean === true) {
    addClass(element, classToToggle);
  } else if (forceBoolean === false) {
    removeClass(element, classToToggle);
  } else if (hasClass(element, classToToggle)) {
    removeClass(element, classToToggle);
  } else {
    addClass(element, classToToggle);
  }
};

