import * as ErrorStackParse from 'error-stack-parser';
import { getPlaybackURL, VERSION } from './config';

// based on the Rollbar API
// https://docs.rollbar.com/v1.0.0/reference#section-data-format<Paste>
export default function createErrorReport({
  error,
  location,
  message,
  navigator,
  timeStamp,
  type,
  vyGlobal,
}) {
  return {
    data: {
      // Required: body
      // The main data being sent. It can either be a message, an exception, or a crash report.
      body: {
        // If this payload is a single exception, use "trace"
        // A list of stack frames, ordered such that the most recent call is last in the list.
        trace: {
          // Required: exception
          // An object describing the exception instance.
          exception: {
            // Required: class
            // The exception class name.
            class: error.name,

            // Optional: message
            // The exception message, as a string
            message,
          },
          // Each frame is an object.
          frames: ErrorStackParse.parse(error).map((frame) => ({
            // Optional: code
            // The line of code
            code: frame.source,

            // Optional: colno
            // The column number as an integer
            colno: frame.columnNumber,

            // Required: filename
            // The filename including its full path.
            filename: frame.fileName,

            // Optional: colno
            // The column number as an integer
            lineno: frame.lineNumber,

            // Optional: method
            // The method or function name
            method: frame.functionName,
          })),
        },
      },

      // Optional: client
      // Data about the client device this event occurred on.
      // As there can be multiple client environments for a given event (i.e. Flash running inside
      // an HTML page), data should be namespaced by platform.
      client: {
        // Can contain any arbitrary keys. Rollbar understands the following:

        // Optional: cpu
        // A string up to 255 characters
        cpu: navigator.platform,
        javascript: {
          // Optional: browser
          // The user agent string
          browser: navigator.userAgent,

          // Optional: guess_uncaught_frames
          // Set to true to enable frame guessing
          // See the "Source Maps" guide for more details.
          guess_uncaught_frames: true,

          // Optional: source_map_enabled
          // Set to true to enable source map deobfuscation
          // See the "Source Maps" guide for more details.
          source_map_enabled: true,
        },
      },

      // Optional: code_version
      // A string, up to 40 characters, describing the version of the application code
      // Rollbar understands these formats:
      // - semantic version (i.e. "2.1.12")
      // - integer (i.e. "45")
      // - git SHA (i.e. "3da541559918a808c2402bba5012f6c60b27661c")
      code_version: VERSION,

      // Optional: custom
      // Any arbitrary metadata you want to send. "custom" itself should be an object.
      custom: {
        jsonpRequests: Object.keys(vyGlobal.jsonp),
        playbackUrlOverride: window.VIDYARD_PLAYBACK_URL,
        players: vyGlobal.players.map((player) => ({
          iframe: player.iframe ? { src: player.iframe.src } : {},
          metadata: player.metadata,
          org: player.org
            ? { id: player.org.id, foundIntegrations: player.org.foundIntegrations }
            : {},
          uuid: player.uuid,
        })),
        preloadLinks: vyGlobal.preloadLinks,
      },

      // Required: environment
      // The name of the environment in which this occurrence was seen.
      // A string up to 255 characters. For best results, use "production" or "prod" for your
      // production environment.
      // You don't need to configure anything in the Rollbar UI for new environment names;
      // we'll detect them automatically.
      environment: getPlaybackURL(),

      // Optional: fingerprint
      // A string controlling how this occurrence should be grouped. Occurrences with the same
      // fingerprint are grouped together. See the "Grouping" guide for more information.
      // Should be a string up to 40 characters long; if longer than 40 characters, we'll use its SHA1 hash.
      // If omitted, we'll determine this on the backend.
      fingerprint: error.name,
      // Optional: language
      // The name of the language your code is written in.
      // This can affect the order of the frames in the stack trace. The following languages set the most
      // recent call first - 'ruby', 'javascript', 'php', 'java', 'objective-c', 'lua'
      // It will also change the way the individual frames are displayed, with what is most consistent with
      // users of the language.
      language: 'javascript',

      // Optional: level
      // The severity level. One of: "critical", "error", "warning", "info", "debug"
      // Defaults to "error" for exceptions and "info" for messages.
      // The level of the *first* occurrence of an item is used as the item's level.
      level: type,

      // Optional: platform
      // The platform on which this occurred. Meaningful platform names:
      // "browser", "android", "ios", "flash", "client", "heroku", "google-app-engine"
      // If this is a client-side event, be sure to specify the platform and use a post_client_item access token.
      platform: 'browser',

      // Optional: request
      // Data about the request this event occurred in.
      // Can contain any arbitrary keys. Rollbar understands the following:
      // query_string: the raw query string
      // url: full URL where this event occurred
      request: { query_string: location.search, url: location.href },

      // Optional: timestamp
      // When this occurred, as a unix timestamp.
      timestamp: timeStamp,
    },
  };
}
