/*
* Provides functions which perform JSONP requests to the server.
* All URLs etc are stored within this file.
*/

import Promise from './promise';
import { getBaseURL, getPlaybackURL, INTEGRATIONS_URL, SEO_DETAILS_URL, STYLE_PATH } from './config';
import { log, once } from './embed-helpers';

export const getStyle = (uuid) =>
  new Promise((res, rej) =>
    request({
      error: rej,
      success: res,
      url: `${getBaseURL()}${uuid}${STYLE_PATH}`,
      uuid: `style_${uuid}`,
    }),
  );

export const getIntegrations = (uuid) =>
  new Promise((res, rej) =>
    request({
      error: rej,
      success: res,
      url: `${getBaseURL()}${uuid}${INTEGRATIONS_URL}`,
      uuid: `integrations_${uuid}`,
    }),
  );

export const getSEO = (uuid) =>
  new Promise((res, rej) =>
    request({
      error: rej,
      success: res,
      url: `${getBaseURL()}${uuid}${SEO_DETAILS_URL}`,
      uuid: `details_${uuid}`,
    }),
  );

export const getMarketoLead = (uuid, marketoCookie) =>
  new Promise((res, rej) => {
    const cookie = encodeURIComponent(marketoCookie);
    const contactUrl = `${getBaseURL()}${uuid}/contact/${cookie}/marketoContact.js`;
    request({
      error: rej,
      success: res,
      url: contactUrl,
      uuid: `marketoContact_${uuid}`,
    });
  });


const createJSONPTag = ({ error, url, requestUuid }) => {
  const script = document.createElement('script');
  script.type = 'application/javascript';
  script.onerror = error;
  script.src = `${url}?callback=window.VidyardV4.jsonp.${requestUuid}.done`;
  // Anticipating a potential error when getPlaybackURL() is called when the JSONP script is current:
  // Setting data-playbackurl here makes it safe to use getPlaybackURL during all stages of load.
  script.setAttribute('data-playbackurl', getPlaybackURL());
  document.body.appendChild(script);
  return script;
};

// track all jsonp requests
const jsonpRequests = {};

const request = ({ url, uuid, success, error }) => {
  // Function names can't include "-" because it's interpreted as a subtract operator
  // Strip them out here and in the player backend to prevent errors
  const requestUuid = uuid.replace(/-/g, '');
  const script = createJSONPTag({ error, url, requestUuid });

  // each response callback will only be called once
  const onRes = once((data) => {
    success(data);
    script.parentNode.removeChild(script);
  });

  // store all jsonp requests in a closure
  jsonpRequests[requestUuid] = jsonpRequests[requestUuid] || [];
  jsonpRequests[requestUuid].push(onRes);

  window.VidyardV4.jsonp[requestUuid] = {
    done: (data) => {
      // call data on all response callbacks that share the same request_uuid
      jsonpRequests[requestUuid].forEach((cb) => cb(data));
    },
  };
};
