/*
* Controls showing & hiding of the lightbox
*/
import { getPlaybackURL } from '../utils/config';
import * as EmbedHelpers from '../utils/embed-helpers';
import * as FrameMessenger from '../utils/frame-messenger';
import * as LightboxView from '../views/lightbox';
import { logger } from '../api/debug';
import VidyardPlayer from './vidyard-player';
import DataParams from './data-params';

// --- Private Vars ---
let ACTIVE_ELEMENT;

// need to cleanup these after the lightbox is removed
const LIGHTBOX_EVENTS: { [key: string]: ReturnType<typeof EmbedHelpers.addListener> } = {};

export interface Options {
  container: VidyardPlayer['container'];
  iframe: string;
  overlayZindex: DataParams['overlayZindex'];
  player: VidyardPlayer;
}

export function setupAnimations(options: Options): void {
  const thumbnailContainer = EmbedHelpers.getElementByClass(
    'vidyard-lightbox-centering',
    'div',
    options.container,
  )[0];

  // expose the show and hide functions to the player API
  options.player.showLightbox = function showLightboxHandler(): void {
    logger.setLevel(options.player.uuid)('show lightbox');
    showLightbox({
      container: options.container,
      iframe: options.iframe,
      overlayZindex: options.overlayZindex,
      player: options.player,
    });
  };
  options.player.hideLightbox = () => removeLightbox(options.player);

  // event listeners on the thumbnail
  EmbedHelpers.addListener('click', 'onclick', options.player.showLightbox, thumbnailContainer);

  EmbedHelpers.addListener(
    'keydown',
    'onkeydown',
    EmbedHelpers.spaceOrEnterKeyPressEvent((e: Event): void => {
      e.preventDefault();
      options.player.showLightbox(e);
    }),
    thumbnailContainer,
  );
}

// --- Private Functions ---
function showLightbox(options: Options): void {
  // The lightbox cannot be shown if no iframe HTML was provided
  // or if there is already a lightbox showing
  if (!options.iframe || document.getElementById('vidyard-overlay')) {
    return;
  }

  ACTIVE_ELEMENT = document.activeElement;
  const lightboxDOM = LightboxView.show(options.iframe, options.container, options.overlayZindex);

  // Set the vidyardPlayers iframe to the newly injected one
  options.player.iframe = lightboxDOM.container.iframe;

  // update iframe title to video title once player metadata ready
  options.player.on('metadata', function updateLightboxIframeTitle(args: any[]) {
    const metadata = args[0];
    options.player.iframe.title = metadata.name;
    options.player.off('metadata', updateLightboxIframeTitle);
  });

  // callback to run when the iframe has loaded, only once
  const { uuid } = options.player;
  const { iframe } = lightboxDOM.container;
  const iframeLoaded = onIframeLoad(iframe, uuid, options.player);
  const listenForKeyPress = iframeListenForKeyPress(iframe, uuid);

  // iframe load listener
  LIGHTBOX_EVENTS.iframeLoaded = EmbedHelpers.addListener(
    'load',
    'onload',
    iframeLoaded,
    lightboxDOM.container.iframe,
  );
  // postMessage receipt from the player listener
  LIGHTBOX_EVENTS.messageHandler = EmbedHelpers.addListener(
    'message',
    'onmessage',
    // @TODO add better types for data
    FrameMessenger.receive((data: any): void => {
      iframeLoaded();
      listenForKeyPress();
      onEscInIframe(data, options.player);
    }),
    window,
  );
  // click on lightbox overlay listener
  LIGHTBOX_EVENTS.overlayClick = EmbedHelpers.addListener(
    'click',
    'onclick',
    () => removeLightbox(options.player),
    lightboxDOM.lightbox.overlayWrapper,
  );
  // click on lightbox fixed content wrapper listener
  LIGHTBOX_EVENTS.fixedClick = EmbedHelpers.addListener(
    'click',
    'onclick',
    () => removeLightbox(options.player),
    lightboxDOM.lightbox.contentFixed,
  );
  // space or enter on the X button listener
  LIGHTBOX_EVENTS.closeKeyPress = EmbedHelpers.addListener(
    'keydown',
    'onkeydown',
    EmbedHelpers.spaceOrEnterKeyPressEvent(() => removeLightbox(options.player)),
    lightboxDOM.lightbox.closeContainer,
  );
  LIGHTBOX_EVENTS.responsivePlayerSize = EmbedHelpers.addListener(
    'resize',
    'onresize',
    handleResize,
    window,
  );
}

function handleResize(): void {
  const constraintImage = document.getElementById('vidyard-popbox-constraint');
  const windowRatio = parseFloat(((window.innerHeight / window.innerWidth) * 100).toFixed(2));
  const imageRatio = parseFloat(EmbedHelpers.calcAspectRatio(constraintImage));
  const orientation = windowRatio < imageRatio ? 'landscape' : 'portrait';

  if (constraintImage.className !== orientation) {
    constraintImage.className = orientation;
  }
}

function removeLightbox(player?: VidyardPlayer): void {
  LightboxView.remove();

  if (ACTIVE_ELEMENT) {
    ACTIVE_ELEMENT.focus();
  }

  Object.keys(LIGHTBOX_EVENTS).forEach((key) => {
    const { eventName, handler, element } = LIGHTBOX_EVENTS[key];
    EmbedHelpers.removeListener(eventName, handler, element);
  });

  // Trigger all registered lightboxClose callbacks
  if (player) {
    player.trigger('lightboxClose');
  }
}

// @TODO: add better type for postMessageData
function onEscInIframe(postMessageData: any, player: VidyardPlayer): void {
  // if esc was pressed in the iframe
  if (postMessageData.event === 'keyPressed' && postMessageData.params === 27) {
    removeLightbox(player);
  }
}

function onIframeLoad(iframe: HTMLIFrameElement, uuid: string, player: VidyardPlayer): () => void {
  return EmbedHelpers.once((): void => {
    LightboxView.makeIframeVisible(iframe);

    handleResize();

    // start listening for esc key presses in parent
    LIGHTBOX_EVENTS.parentEsc = EmbedHelpers.addListener(
      'keydown',
      'onkeydown',
      EmbedHelpers.escKeyPressEvent(() => removeLightbox(player)),
      document,
    );
  });
}

// Send message to start listening for esc key presses in iframe
function iframeListenForKeyPress(iframe, uuid) {
  return EmbedHelpers.once((): void => {
    const message = { uuid, event: 'listenForKeyPress', keyCode: '27' };
    FrameMessenger.send(message, `https://${getPlaybackURL()}`, iframe);
  });
}
