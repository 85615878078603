import { createPlaceholder, RenderOptions, loadPlaceholder } from '../models/placeholder';
import { renderPlayer as render } from '../models/renderer';
import { log } from '../utils/embed-helpers';
import messages from '../utils/messages';
import Promise from '../utils/promise';
import { logger } from './debug';

// TODO: change Promise<any> to Promise<Player> once we have a proper type for it
const renderPlayerFromPlaceholder = (image: HTMLImageElement): Promise<any> => {
  logger.setLevel('placeholder')('rendering player from placeholder image');
  const player = render(image);
  // resolve when the player is ready, unless the player is a lightbox
  return new Promise((res) => {
    if (player.showLightbox) {
      res(player);
    } else {
      player.on('ready', () => res(player));
    }
  });
};

type renderPlayerParam = HTMLImageElement | RenderOptions;

export default function renderPlayer(options: renderPlayerParam): Promise<object> {
  // TODO: instead of creating an image make render.renderPlayer work with an options objects
  const placeholder =
    options instanceof HTMLImageElement ? options : createPlaceholder(options);

  return loadPlaceholder(placeholder)
    .then(renderPlayerFromPlaceholder)
    .catch((e) => log(messages.errors.placeholder, 'warn'));
}
