import { addListener } from '../utils/embed-helpers';
import { getPlaybackURL } from '../utils/config';
import { send } from '../utils/frame-messenger';
import VidyardPlayer from '../models/vidyard-player';
import { DetailsPayload } from './seo';

const SKIP_BACKLINK_HOSTNAMES_RE = /(vidyard|vystaging|hubspot)\.com$/;

const injectBacklink = (player: VidyardPlayer, data: DetailsPayload, force: boolean = false): void => {
  if (!force && (!data || !data.hasBacklink || SKIP_BACKLINK_HOSTNAMES_RE.test(document.location.host))) {
    return;
  }

  const linkEl = document.createElement('a');
  const linkTextEl = document.createElement('span');
  const imgEl = document.createElement('img');

  imgEl.src = 'https://cdn.vidyard.com/client-logos/vidyard.png';
  imgEl.id = 'backlink-icon';
  imgEl.alt = 'Vidyard Logo';

  linkTextEl.id = 'backlink-text';
  linkTextEl.textContent = 'Powered by Vidyard';

  linkEl.id = 'backlink';
  linkEl.appendChild(imgEl);
  linkEl.appendChild(linkTextEl);
  linkEl.href = data.backlinkURL;
  linkEl.rel = 'nofollow';
  linkEl.target = '_blank';
  linkEl.setAttribute('aria-label', 'Link to Vidyard');
  linkEl.dataset.testid = 'backlink';

  addListener(
    'click',
    'onclick',
    () => {
      const message = {
        data: {
          event: 'Acquisition - Clicked the Backlink',
        },
        event: 'sendMetricsEvent',
        uuid: player.uuid,
      };

      send(message, `https://${getPlaybackURL()}`, player.iframe);
    },
    linkEl,
  );

  player.container.appendChild(linkEl);
};

export default injectBacklink;
