/*
* Root embed script file. Communicates and manages everything within the /embed sub-folders
* Exports nothing, on initial page ready the first available script will
*   1) Define window.VidyardV4, which acts as the public player API
*   2) Sweep the document for any player <img/>s & embed them as needed
*   3) For each newly found embed, a VidyardPlayer object will be added to the window.VidyardV4.players array
*
* On subsequent script invocations an event will be fired and only steps 2-3) will be repeated
*/
import preloadFrames from './models/preload-frames';
import { addListener } from './utils/embed-helpers';
import VidyardV4Global from './api/vidyard-v4-global';
import VidyardGlobal from './api/vidyard-global';
import './css/style.css';
import logErrors from './utils/error-logger';

// --- Runs on script execution ---
logErrors();

const { _debug, api, players } = VidyardV4Global;

// Bind legacy globals
window.VidyardV4 = window.VidyardV4 || VidyardV4Global;
window.Vidyard = window.Vidyard || VidyardGlobal;

// --- Runs on script execution ---
switch (document.readyState) {
  case 'complete':
    api.renderDOMPlayers();
    break;
  case 'interactive':
    preloadFrames();
    addListener('load', 'onload', () => api.renderDOMPlayers(), window);
    break;
  default:
    // wait until document load completes
    addListener('load', 'onload', () => api.renderDOMPlayers(), window);
}

// Export the public API
export { _debug, api, players };
export default VidyardV4Global;
