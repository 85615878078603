import IntegrationsWatcher, { IntegrationsWatcherInstance } from '../models/integrations-watcher';
import { sweepDocument } from '../models/renderer';
import Player from '../models/vidyard-player';
import addReadyListener from './add-ready-listener';
import destroyPlayer from './destroy-player';
import { consent, hasConsentOnReady } from './gdpr';
import getPlayersByUUID from './get-players-by-uuid';
import progressEventsWrapper from './progress-events-wrapper';
import renderPlayer from './render-player';
import debug, { Debug as DebugType } from './debug';
import getPlayerMetadata from './get-player-metadata';

interface API {
  GDPR: {
    _readyConsent?: boolean | undefined;
    consent: typeof consent;
    hasConsentOnReady: typeof hasConsentOnReady;
  };
  addReadyListener: typeof addReadyListener;
  destroyPlayer: typeof destroyPlayer;
  getPlayerMetadata: typeof getPlayerMetadata;
  getPlayersByUUID: typeof getPlayersByUUID;
  progressEvents: typeof progressEventsWrapper;
  renderDOMPlayers: typeof sweepDocument;
  renderPlayer: typeof renderPlayer;
}

export interface VidyardEmbed {
  VERSION: string;
  api: API;
  players: Player[];
}

export interface VidyardV4 {
  _debug: DebugType;
  api: API;
  integrations: IntegrationsWatcherInstance;
  jsonp: { [uuid: string]: { done: (data: any) => void } };
  playerReadyListeners: { [key: string]: Array<([...x]: [any]) => any> };
  players: Player[];
  preloadLinks: string[];
}

const vidyardV4Global: VidyardV4 = {
  _debug: debug,
  api: {
    GDPR: { consent, hasConsentOnReady },
    addReadyListener,
    destroyPlayer,
    getPlayerMetadata,
    getPlayersByUUID,
    progressEvents: progressEventsWrapper,
    renderDOMPlayers: sweepDocument,
    renderPlayer,
  },
  integrations: new IntegrationsWatcher(),
  jsonp: {},
  // Just ensure that the undefined uuid option is always present
  playerReadyListeners: { undefined: [] },
  players: [],
  preloadLinks: [],
};

export default vidyardV4Global;
