import Shylog from 'shylog';
import { VERSION as version, setPlaybackURL, getPlaybackURL } from '../utils/config';
import { getCurrentScript, log } from '../utils/embed-helpers';

export const logger = new Shylog({
  emit: !!window.location.search.match('vydebug=1'),
  logger: (message) => log(message, 'info'),
});

const currentScript = getCurrentScript();
if (currentScript) {
  logger.info(`vidyardEmbed V${version} loaded from ${currentScript.src}`);
  logger.info(`data-playbackurl=${currentScript.getAttribute('data-playbackurl')}`);
}

logger.info(`userAgent ${window.navigator.userAgent}`);
logger.info(`cookies ${navigator.cookieEnabled}`);

if (window.performance && window.performance.timing && window.performance.timing.navigationStart) {
  logger.info(`Script load time ${Date.now() - window.performance.timing.navigationStart}`);
}

export interface Debug {
  getPlaybackURL: typeof getPlaybackURL,
  logger: Shylog;
  setPlaybackURL: typeof setPlaybackURL;
  version: typeof version;
}

const debug: Debug = { logger, getPlaybackURL, setPlaybackURL, version };

export default debug;
