/*
* Provides an ie7 compatable way of sending messages to iframes
* If in a modern browser, will just use postmessage
*/

import * as EmbedHelpers from './embed-helpers';

// --- Public Functions ---

export const send = (message, targetOrigin, target) => {
  if (!targetOrigin || !target) {
    return;
  }

  if (window.postMessage && target.contentWindow) {
    // The browser supports window.postMessage, so call it with a targetOrigin
    // set appropriately, based on the target_url parameter.
    target.contentWindow.postMessage(JSON.stringify(message), targetOrigin);
  } else {
    // If the window does not support postmessaging, only 'associateVisitor' events are supported
    // Internally the player will have specific handling for these cases
    if (message.event !== 'associateVisitor') {
      return;
    }

    const stringMessage = `${message.event},${message.data.type},${message.data.value}|`;
    let { src: targetSrc } = target;

    if (targetSrc.indexOf('#') === -1) {
      targetSrc += '#';
    }

    // The browser does not support window.postMessage, so set the location
    // of the target to target_url#message. A bit ugly, but it works!
    targetSrc += stringMessage;
    target.src = targetSrc;
  }
};

export const receive = (callback) => (postMessage) => {
  const data = parse(postMessage);
  if (!data) {
    return;
  }
  callback(data);
};

// --- Private Functions ---
function parse(event) {
  if (typeof event.data !== 'string' || event.data === '') {
    return;
  }

  try {
    const data = JSON.parse(event.data);
    const iframe = EmbedHelpers.getElementByClass(
      'vidyard-iframe-' + data.uuid,
      'iframe',
    )[0];

    // event validation
    const contentWindow = iframe ? iframe.contentWindow : null;
    const sameSource = contentWindow === event.source;
    const validEvent = typeof data.event === 'string';

    if (!sameSource || !validEvent) {
      return;
    }

    return data;
  } catch (e) {
    EmbedHelpers.log('Invalid postMessage received', 'warn');
    return;
  }
}
