import { logger } from '../api/debug';
import { calcAspectRatio, addListener } from '../utils/embed-helpers';
import { createIframe } from '../models/markup-injector';
import * as LightboxAnimator from '../models/lightbox-animator';
import * as LightboxPageThumbnail from '../models/lightbox-page-thumbnail';
import { getStyle } from '../utils/jsonp';
import DataParams from '../models/data-params';
import VidyardPlayer from '../models/vidyard-player';

const injectLightboxElements = ({
  dataParams,
  player,
}: {
  dataParams: DataParams;
  player: VidyardPlayer;
}): void => {
  const log = logger.setLevel(dataParams.uuid);
  log(`injecting lighbox embed`);

  // hide the placeholder image right away if lightbox
  player.placeholder.style.display = 'none';

  const iframeSizing = { ratio: calcAspectRatio(player.placeholder) };
  const playerIframe = createIframe(dataParams, iframeSizing);

  const thumbnailSizing = {
    maxHeight: dataParams.height ? parseInt(dataParams.height, 10) : null,
    maxWidth: dataParams.width ? parseInt(dataParams.width, 10) : null,
    ratio: calcAspectRatio(player.placeholder),
  };

  // Inject lightbox thumbnail
  LightboxPageThumbnail.init(player.container, dataParams, thumbnailSizing);
  LightboxAnimator.setupAnimations({
    container: player.container,
    iframe: playerIframe,
    overlayZindex: dataParams.overlayZindex,
    player,
  });

  log(`getStyle sent`);
  getStyle(player.uuid).then((data) => {
    log(`getStyle received: ${JSON.stringify(data)}`);
    // Update the play button of the lightbox thumbnail to match the colours on the model
    LightboxPageThumbnail.applyLightboxPlayButton(data, player.container);
  }).catch(e => {
    log('getStyle failed, likely a network error');
  });
};

export default injectLightboxElements;
