import Player from '../../models/vidyard-player';

interface Listener {
  callback: ListenerCallback;
  lastTimeUpdate: number;
  partOfVideoWatched: {};
  player: Player;
  thresholds: number[];
  timeWatched: number;
}

type ListenerCallback = (params: {
  chapter: number;
  event: number;
  player: Player;
  videoIndex: number;
}) => null;

const templates = {};
const listeners = {};

export function addTemplate(player: Player, callback: ListenerCallback, thresholds: number[]): void {
  // Prepare the listeners object when the template gets registered
  if (typeof listeners[player.uuid] !== 'object') { listeners[player.uuid] = {}; }

  templates[player.uuid] = {
    callback,
    thresholds: [...thresholds].sort((a, b) => b - a),
  };
}

function copyTemplate(player: Player, videoIndex: number): void {
  addListener(
    player,
    videoIndex,
    templates[player.uuid].callback,
    [...templates[player.uuid].thresholds],
  );
}

export function getListener(player: Player, videoIndex: number): Listener {
  // If there's no template, then there's no listeners
  if (typeof templates[player.uuid] !== 'object') { return undefined; }

  // If necessary, create the listener using the matching template
  if (typeof listeners[player.uuid][videoIndex] !== 'object') { copyTemplate(player, videoIndex); }

  return listeners[player.uuid][videoIndex];
}

// Adds a new listeners entry based on player/videoIndex
function addListener(player: Player, videoIndex: number, callback: ListenerCallback, thresholds: number[]): void {
  listeners[player.uuid][videoIndex] = {
    callback,
    lastTimeUpdate: player.currentTime(),
    partOfVideoWatched: {},
    player,
    thresholds,
    timeWatched: 0,
  };
}

export function removeTemplateAndListeners(player: Player): void {
  templates[player.uuid] = undefined;
  listeners[player.uuid] = undefined;
}

// Updates the new listeners entry based on player/videoIndex.
// Trigger the callback for any thresholds passed in the process.
export function updateTimeWatched(player: Player, time: number): void {
  const videoIndex = player.getCurrentVideoIndex();
  const listener = getListener(player, videoIndex);
  if (
    typeof listener === 'undefined'
    || typeof listener.player.metadata !== 'object'
    || listener.thresholds.length === 0
   ) {
    return;
  }

  const deltaTime = time - listener.lastTimeUpdate;
  listener.lastTimeUpdate = time;

  // If the time change moved backwards or was > 1 second,
  // then assume the player was seeking and ignore this timeupdate
  if (deltaTime <= 0 || deltaTime > 1) { return; }

  if (time >= 1) {
    listener.partOfVideoWatched[Math.floor(time)] = 1;
  }

  // Trigger the callback for any thresholds that have been passed since the last timeupdate.
  // Progress events can only be triggered once, so remove the threshold when it's hit.
  listener.timeWatched = Object.keys(listener.partOfVideoWatched).length;
  const durationInMilliseconds = listener.player.metadata.chapters_attributes[videoIndex].video_attributes.length_in_milliseconds;
  const durationInSecondsFloored = Math.floor(durationInMilliseconds / 1000); // This is because `length_in_seconds` rounds upwards
  const percentWatched = listener.timeWatched / durationInSecondsFloored * 100;

  while (percentWatched >= listener.thresholds[listener.thresholds.length - 1]) {
    listener.callback({
      // Deprecated. Only included for backwards compatibility
      chapter: videoIndex,

      event: listener.thresholds.pop(),
      player,
      videoIndex,
    });
  }
}

export default function setupEventListeners(player: Player, callback: ListenerCallback, thresholds: number[]) {
  // Instead of creating a listener for each chapter,
  // store a template that will create the listener when needed.
  // Doing so allows progressEvents() to be registered before the player ready event is fired
  addTemplate(player, callback, thresholds);

  const onTimeUpdate = (time: number): void => updateTimeWatched(player, time);
  player.on('timeupdate', onTimeUpdate);

  return {
    player,
    removeEventListeners: (): void => {
      player.off('timeupdate', onTimeUpdate);
      removeTemplateAndListeners(player);
    },
  };
}
