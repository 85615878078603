/*
* Injection of the base html / CSS for the player iframe
* Does not handle showing/hiding lightboxes, see LightboxHelper for that madness
*/
import { getPlaybackURL } from '../utils/config';
import DataParams from './data-params';

export interface SizingMap {
  maxHeight?: string;
  maxWidth?: string;
  ratio: string;
}

const allowFeaturePolicy = (): string => {
  const features = [
    'autoplay',
    'fullscreen',
    'picture-in-picture',
    'camera',
    'microphone',
    'display-capture',
    'clipboard-write'
  ];

  return features.join('; ');
};

export const createIframe = (dataParams: DataParams, sizing: SizingMap): string => {
  // We only want to limit the max-height and max-width for inline embeds
  // Can't apply this directly to the iframe element because it affects fullscreen in IE11
  const maxSizeDiv = (children: string): string => (
    dataParams.type === 'inline' ? (
      '<div ' +
        'class="vidyard-inner-container-' + dataParams.uuid + '" ' +
        'style="' +
          'position: absolute;' +
          'height: 100%; ' +
          'width: 100%; ' +
          (sizing.maxWidth ? 'max-width: ' + sizing.maxWidth + 'px; ' : '') +
          (sizing.maxHeight ? 'max-height: ' + sizing.maxHeight + 'px; ' : '') +
        '"' +
      '> ' +
        children +
      '</div> '
    ) : children
  );

  return (
    '<div ' +
      'class="vidyard-div-' + dataParams.uuid + '" ' +
      'role="region" ' +
      'aria-label="Vidyard media player" ' +
      'style="' +
        // This would change to relative once the iframe is loaded
        'position: absolute; ' +
        'padding-bottom: ' + sizing.ratio + '%; ' +
        'height: 0; ' +
        'overflow: hidden; ' +
        'max-width: 100%; ' +
      '"' +
    '>' +
      maxSizeDiv(
        '<iframe ' +
            'allow="' + allowFeaturePolicy() + '"' +
            'allowfullscreen ' +
            'allowtransparency="true" '+
            'referrerpolicy="no-referrer-when-downgrade" ' +
            'class="vidyard-iframe-' + dataParams.uuid + '" ' +
            'frameborder=0 '+
            'height="100%" ' +
            'width="100%" ' +
            'scrolling=no ' +
            'src="https://' + getPlaybackURL() + '/' + dataParams.uuid + dataParams.toQueryString() + '" ' +
            'title="Vidyard video player" ' +
            'style="' +
              'opacity: 0; ' +
              'background-color: transparent; ' +
              'position: absolute; ' +
              'top: 0; ' +
              'left: 0;' +
            '"' +
        '>' +
        '</iframe>'
      ) +
  '</div>'
  );
}

// @TODO: better data type
export const createJsonLD = (data: any): HTMLScriptElement => {
  const playerDetailsScriptTag = document.createElement('script');
  playerDetailsScriptTag.type = 'application/ld+json';
  playerDetailsScriptTag.text = JSON.stringify(data);
  return playerDetailsScriptTag;
};
