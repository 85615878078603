import { removeDuplicates } from './progress-events/remove-duplicates';
import setupEventListeners from './progress-events/setup-event-listeners';
import VidyardPlayer from '../models/vidyard-player';
import { log } from '../utils/embed-helpers';

interface Players {
  [uuid: string]: VidyardPlayer | '';
}

// Initialize analytics for each player when called
function progressEvents(
  callback,
  thresholds = [1, 25, 50, 75, 90],
  singlePlayerScope?: VidyardPlayer,
) {
  const removeEventListeners = [];
  const players: Players = singlePlayerScope
    ? { [singlePlayerScope.uuid]: singlePlayerScope }
    : getPlayers();

  // Sort and unique the array in case we are passed an unusual array of thresholds
  const dedupedThresholds = removeDuplicates(thresholds);

  for (const uuid in players) {
    if (players.hasOwnProperty(uuid) && uuid.length > 0 && players[uuid] !== '') {
      // set the listener
      const removeCb = setupEventListeners(players[uuid], callback, dedupedThresholds);
      removeEventListeners.push(removeCb);
    } else {
      log(() =>
        console.error(
          `Could not attach Progress Events to player ${uuid}, make sure to load the Vidyard Player API`,
        ),
      );
    }
  }

  if (removeEventListeners.length === 0) {
    log(() =>
      console.warn('No Vidyard Players found. (include this script below player embed codes)'),
    );
  }

  return removeEventListeners;
}

function getPlayers(): Players | {} {
  try {
    return window.Vidyard.players();
  } catch (e) {
    log(() =>
      console.error('The Vidyard Player API must be loaded before this script can execute'),
    );
    return {};
  }
}

export default function progressEventsWrapper(...args) {
  const subscriptions = progressEvents(...args);

  Object.keys(subscriptions).forEach((k) => {
    const { player, removeEventListeners } = subscriptions[k];
    if (player.progressEventsUnsubscribe) {
      player.progressEventsUnsubscribe.push(removeEventListeners);
    }
  });
}
