// _VERSION is the package version string coming from package.json via Webpack
declare const _VERSION: string;

export const VERSION = _VERSION;
export const ERROR_ORIGIN_RE = /vidyard\.com\/embed\/v4/;
export const INTEGRATIONS_URL = '/integrations.js';
export const OVERLAY_FADE_TIME = 0.5;
export const PLAYLIST_WIDTH = 319;
export const SEO_DETAILS_URL = '/details.js';
export const STYLE_PATH = '/style.js';
