import PromisePolyfill from 'promise-polyfill';

const isFn = (fn) => typeof fn === 'function';

const checkNativePromise = ({ Promise } = window) =>
  Promise &&
  Promise.all &&
  Promise.race &&
  Promise.resolve &&
  Promise.reject &&
  isFn(Promise) &&
  isFn(Promise.all) &&
  isFn(Promise.race) &&
  isFn(Promise.resolve) &&
  isFn(Promise.reject);

const promiseFn = checkNativePromise() ? window.Promise : PromisePolyfill;

export default promiseFn;
