export function consent(userConsent) {
  if (userConsent === undefined) {
    return;
  }

  const { players } = window.VidyardV4;

  for (const player of players) {
    player.on('ready', () => {
      player.consentToGDPR(userConsent);
    });
  }
}

// Used to determine whether or not to ask for GDPR consent on page load
// _readyConsent does not change based on calls to the consent function to limit usage
// to the expected use case at page load
export function hasConsentOnReady(callback) {
  let playersReady = 0;
  const { GDPR: gdpr } = window.VidyardV4.api;
  const { players } = window.VidyardV4;

  if (players.length === 0) {
    return callback(false);
  }
  if (gdpr._readyConsent !== undefined) {
    return callback(gdpr._readyConsent);
  }

  // Make sure all the players are ready before returning the consent
  for (const player of players) {
    player.on('ready', () => {
      if (playersReady !== players.length) {
        playersReady += 1;
      }

      if (playersReady === players.length) {
        // TODO: can't use reduce in old IE, add helper
        gdpr._readyConsent = players.reduce((c, p) => p.status.consent && c, true);
        return callback(gdpr._readyConsent);
      }
    });
  }
}
