import { TmpOrg, Org } from './integrations-watcher';
import { getPlaybackURL } from '../utils/config';
import * as EmbedHelpers from '../utils/embed-helpers';
import * as FrameMessenger from '../utils/frame-messenger';

export default class Player {
  public _tmpOrg: TmpOrg;
  public org: Org;
  public container: HTMLDivElement;
  public element: HTMLElement;
  public iframe: HTMLIFrameElement;
  public metadata: StatusData['metadata'] | null;
  public placeholder: HTMLImageElement;
  public progressEventsUnsubscribe: Array<() => void>;
  public status: StatusData['status'] | null;
  public uuid: string;

  // These methods are populated by lightbox-animator.ts
  public showLightbox: (e?: any) => void;
  public hideLightbox: () => void;

  private _ready = false;
  private _previousTime = null;
  private _callbackStore: CallbackStore;

  constructor(element: HTMLElement, uuid: string, callbackStore?: CallbackStore) {
    this._callbackStore = callbackStore || {
      beforeSeek: [],
      chapterComplete: [],
      createCta: [],
      fullScreenChange: [],
      lightboxClose: [],
      metadata: [],
      pause: [],
      play: [],
      playerComplete: [],
      ready: [],
      seek: [],
      sidePlaylistOpen: [],
      status: [],
      timeupdate: [],
      videoComplete: [],
      volumeChange: [],
    };

    this.element = element;
    this.uuid = uuid;
    this.status = null;
    this.metadata = null;
    this.progressEventsUnsubscribe = [];

    EmbedHelpers.addListener('message', 'onmessage', this._onMessageEventHandler);
  }

  public on(eventName: keyof CallbackStore, callback: GivenCallback) {
    if (eventName === 'ready' && this.ready()) {
      setTimeout(() => callback.call(this, undefined, this), 0);
      return;
    }

    if (this._callbackStore[eventName] === undefined) {
      this._callbackStore[eventName] = [callback];

      EmbedHelpers.log(
        `The event name: ${eventName} is not supported, your handler was setup regardless`,
        'warn',
      );

      return;
    }

    this._callbackStore[eventName].push(callback);
  }

  public trigger(eventName: keyof CallbackStore, ...args) {
    for (const callback of this._callbackStore[eventName]) {
      if (eventName === 'ready' && this.ready()) {
        setTimeout(() => callback.call(this, args, this), 0);
      } else {
        callback.call(this, args, this);
      }
    }
  }

  public off(eventName: keyof CallbackStore, callback: GivenCallback) {
    if (eventName === undefined) {
      // No event name given, clear out all event handlers
      for (const evt in this._callbackStore) {
        if (this._callbackStore.hasOwnProperty(evt)) {
          this._callbackStore[evt] = [];
        }
      }
      return;
    }

    // If no handlers have been created with this eventName, do nothing
    if (!this._callbackStore[eventName]) {
      return;
    }

    if (callback) {
      // Only remove the callback associated with the correct function
      const index = EmbedHelpers.indexOfArray(callback, this._callbackStore[eventName]);
      if (index > -1) {
        this._callbackStore[eventName].splice(index, 1);
      }
    } else {
      // Remove all callbacks associated with this eventName
      this._callbackStore[eventName] = [];
    }
  }

  public ready() {
    return this._ready;
  }

  // API METHODS
  public play() {
    this._message({ event: 'play' });
  }

  public pause() {
    this._message({ event: 'pause' });
  }

  public resume() {
    this._message({ event: 'resume' });
  }

  public seek(position) {
    this._message({ event: 'seek', position });
  }

  public setVolume(newVolume) {
    this._message({ event: 'setVolume', newVolume });
  }

  public setPlaybackSpeed(speed) {
    this._message({ event: 'setPlaybackSpeed', speed });
  }

  public playChapter(chapterIndex) {
    EmbedHelpers.log('playChapter() is deprecated. We recommend to use playVideoAtIndex() instead.');
    this.playVideoAtIndex(chapterIndex);
  }

  public playVideoAtIndex(index: number) {
    this._message({ chapter_index: index, event: 'playChapter' });
  }

  public setAudioTrack(audioTrackId) {
    this._message({ audioTrackId, event: 'setAudioTrack' });
  }

  public enableCaption(label, language) {
    this._message({ event: 'enableCaption', label, language });
  }

  public disableCaption(label, language) {
    this._message({ event: 'disableCaption', label, language });
  }

  public consentToGDPR(consent) {
    this._message({ consent, event: 'consentToGDPR' });
  }

  public createCta(attributes) {
    this._message({
      attributes: EmbedHelpers.shallowMerge(
        {
          display_once: false,
          duration: 10,
          fullscreen: false,
          html: '',
          opacity: 1.0,
          start: 0,
          width: 300,
        },
        attributes,
      ),
      event: 'createCta',
    });
  }

  public updateCta(ctaId, attributes) {
    this._message({
      attributes,
      event: 'updateCta',
      id: ctaId,
    });
  }

  public createVideoSection(title, milliseconds) {
    this._message({ event: 'createVideoSection', title, milliseconds });
  }

  public updateVideoSection(index, title, milliseconds) {
    this._message({ event: 'updateVideoSection', index, title, milliseconds });
  }

  public deleteVideoSection(index) {
    this._message({ event: 'deleteVideoSection', index });
  }

  public addEvent({ start = 0, duration = 1, videoIndex, chapterIndex, eventId }) {
    if (!eventId) {
      EmbedHelpers.log('Missing arguments. Need eventId');
      return;
    }

    if (chapterIndex >= 0) {
      EmbedHelpers.log('Parameter chapterIndex is deprecated. We recommend to use videoIndex instead.');
    }

    // We need to default chapterIndex to 0 because that's what we did previously, and we need to ensure all changes are backwards compatible
    let index = 0;
    index = chapterIndex ? chapterIndex : index;
    index = videoIndex ? videoIndex : index;

    this._message({
      chapterIndex: index,
      duration,
      event: 'addEvent',
      id: eventId,
      start,
    });
  }

  public getCurrentChapter() {
    EmbedHelpers.log('getCurrentChapter() is deprecated. We recommend to use getCurrentVideoIndex() instead.');
    return this.getCurrentVideoIndex();
  }

  public getCurrentVideoIndex() {
    return this.status === null ? null : this.status.chapterIndex;
  }

  public currentTime() {
    return this.status === null ? null : this.status.currentTime;
  }

  public scrubbing() {
    return this.status === null ? null : this.status.scrubbing;
  }

  public toggleFullscreen() {
    // Request iframe to go fullscreen if possible, to get around user gesture requirements
    const fullscreenAPI = EmbedHelpers.getFullscreenAPI();

    if (fullscreenAPI) {
      const fullscreenPromise = this.iframe[fullscreenAPI.requestFullscreen]();
      if (fullscreenPromise) {
        fullscreenPromise.then(() => {
          this._message({ event: 'toggleFullscreen' });
        });
      } else {
        this._message({ event: 'toggleFullscreen' });
      }

      // Listens to when the window exits fullscreen via the 'Esc' key
      EmbedHelpers.addListener(fullscreenAPI.fullscreenchange, 'MSFullscreenChange', () => {
        if (!document[fullscreenAPI.fullscreenElement]) {
          this._message({ event: 'exitFullscreen' });
        }
      });

      EmbedHelpers.addListener('message', 'onmessage', FrameMessenger.receive((data) => {
        if (data.event === 'fullScreenChange' && data.params === false) {
          if (document[fullscreenAPI.fullscreenElement]) {
            document[fullscreenAPI.exitFullscreen]();
          }
        }
      }));
    } else {
      // Simply send message for iOS
      this._message({ event: 'toggleFullscreen' });
    }
  }

  public resetPlayer() {
    this._message({ event: 'resetPlayer' });
  }

  private _message(options: MessageOptions) {
    if (this.ready() !== true) {
      EmbedHelpers.log('Player is not ready yet! No messages can be recieved.', 'error');
      return;
    }

    FrameMessenger.send({ ...options, uuid: this.uuid }, `https://${getPlaybackURL()}`, this.iframe);
  }

  private _updateStatus(data: StatusData) {
    if (typeof data.status === 'object') {
      this.status = data.status;
    }

    if (typeof data.metadata === 'object') {
      this.metadata = data.metadata;
      this.trigger('metadata', data.metadata);
    }

    if (this.status) {
      if (this.status.currentTime !== this._previousTime && this._callbackStore.timeupdate) {
        for (const callback of this._callbackStore.timeupdate) {
          callback.call(this, this.status.currentTime, this);
        }
      }

      this._previousTime = this.status.currentTime;
    }
  }

  private _onMessageEventHandler = (event: MessageEvent) => {
    if (event.origin !== `https://${getPlaybackURL()}`) {
      return;
    }

    let data;
    try {
      data = JSON.parse(event.data);
    } catch (e) {
      return;
    }

    // Event origin iframe is not the iframe owned by this player
    if (this.iframe && event.source && this.iframe.contentWindow !== event.source) {
      return;
    }

    // For a different player
    if (this.uuid && data.uuid !== this.uuid) {
      return;
    }

    // All vy events will have a string event name
    if (typeof data.event !== 'string') {
      return;
    }

    this._updateStatus(data);

    if (data.event === 'ready') {
      this._ready = true;
      window.VidyardV4.integrations.updatePlayer(this);
    }

    const eventCallbacks = this._callbackStore[data.event];
    if (eventCallbacks) {
      for (const callback of eventCallbacks) {
        callback.call(this, data.params, this);
      }
    }
  };
}

type GivenCallback = (this: Player, a: undefined, player: Player) => any;

interface CallbackStore {
  beforeSeek: GivenCallback[];
  chapterComplete: GivenCallback[];
  createCta: GivenCallback[];
  fullScreenChange: GivenCallback[];
  lightboxClose: GivenCallback[];
  metadata: GivenCallback[];
  pause: GivenCallback[];
  play: GivenCallback[];
  playerComplete: GivenCallback[];
  ready: GivenCallback[];
  seek: GivenCallback[];
  sidePlaylistOpen: GivenCallback[];
  status: GivenCallback[];
  timeupdate: GivenCallback[];
  videoComplete: GivenCallback[];
  volumeChange: GivenCallback[];
}

interface CtaAttributes {
  display_once: boolean;
  duration: number;
  fullscreen: boolean;
  html: string;
  opacity: number;
  start: number;
  width: number;
}

interface StatusData {
  event: string;
  params?: number | number[] | { start: number };
  metadata?: {
    chapters_attributes: Array<{
      video_attributes: {
        captions: any[];
        description: string | null;
        length_in_milliseconds: number;
        length_in_seconds: number;
        name: string;
        sd_url: string;
        status: string;
        tags: any[];
        thumbnail_urls: {
          normal: string;
          play_button: string;
          play_button_small: string;
          small: string;
        };
      };
    }>;
    custom_attributes: any[];
    description: string;
    height: number;
    length_in_seconds: number;
    name: string;
    tags: any[];
    uuid: string;
    width: number;
  };
  status: {
    chapterIndex: number;
    consent: boolean;
    currentTime: number;
    playerLoadId: string;
    scrubbing: boolean;
    videoId: number;
  };
  uuid: number;
}

type MessageOptions =
  | { event: 'addEvent'; chapterIndex: number; duration: number; id: number; start: number }
  | { event: 'consentToGDPR'; consent: boolean }
  | { event: 'createCta'; attributes: CtaAttributes }
  | { event: 'disableCaption'; label: string; language: string }
  | { event: 'enableCaption'; label: string; language: string }
  | { event: 'pause' }
  | { event: 'play' }
  | { event: 'playChapter'; chapter_index: number }
  | { event: 'resetPlayer' }
  | { event: 'resume' }
  | { event: 'seek'; position: number }
  | { event: 'setAudioTrack'; audioTrackId: number }
  | { event: 'setPlaybackSpeed'; speed: number }
  | { event: 'setVolume'; newVolume: number }
  | { event: 'toggleFullscreen' }
  | { event: 'exitFullscreen' }
  | { event: 'updateCta'; attributes: CtaAttributes; id: number }
  | { event: 'createVideoSection'; title: string; milliseconds: number }
  | { event: 'updateVideoSection'; index: number; title: string; milliseconds: number }
  | { event: 'deleteVideoSection'; index: number };
