import { getPlaybackURL } from '../utils/config';
import { xhrRequest } from '../utils/embed-helpers';
import { logger } from './debug';

interface Metadata {
  chapters_attributes: Array<{
    video_attributes: {
      captions: any[];
      description: string | null;
      length_in_milliseconds: number;
      length_in_seconds: number;
      name: string;
      sd_url: string;
      status: string;
      tags: any[];
      thumbnail_urls: {
        normal: string;
        play_button: string;
        play_button_small: string;
        small: string;
      };
    };
  }>;
  custom_attributes: any[];
  description: string;
  height: number;
  length_in_seconds: number;
  name: string;
  tags: any[];
  uuid: string;
  width: number;
}

export default function getPlayerMetadata(uuid: string): Metadata | void {
  if (!uuid || typeof uuid !== 'string' || uuid.length < 20) {
    throw new Error('Invalid UUID given');
    return;
  }
  const log = logger.setLevel(uuid);

  // @TODO: add proper API to fetch metadata as this endpoint is private and not stable
  const metadataEndpoint = `https://${getPlaybackURL()}/player/${uuid}.json?pomo=0&pomo_reason=fetchMetaData`;
  log('fetching metadata');

  return xhrRequest({ endpoint: metadataEndpoint })
    .then(JSON.parse)
    .then(
      (data: any): Metadata => {
        log('metadata successfully fetched');
        return { uuid, ...data.payload.vyContext.metadata };
      },
    )
    .catch((e) => {
      log(`failed to fetch metadata, ${e}`);
      throw new Error('Error parsing player metadata, make sure the UUID is correct')
    });
}
