/*
* The following helpers are needed for an iOS modal accessibility workaround
* https://www.tpgi.com/the-current-state-of-modal-dialog-accessibility/
*/

/*
* Apply aria-hidden="true" for all children of element, and if necessary
* storing the current aria-hidden value as data-vy-keep-hidden
*/
export const ariaHideChildElements = (
  element: HTMLElement = document.body,
) => {
  for (const el of Array.from(element.children)) {
    if (el.hasAttribute('aria-hidden')) {
      el.setAttribute('data-vy-keep-hidden', el.getAttribute('aria-hidden'));
    }

    el.setAttribute('aria-hidden', 'true');
  }
};

/*
* Restore the previous aria-hidden value for all children of element
* data-vy-keep-hidden indicates the previous value was "true"
*/
export const undoAriaHideChildElements = (
  element: HTMLElement = document.body,
) => {
  for (const el of Array.from(element.children)) {
    if (el.getAttribute('data-vy-keep-hidden')) {
      el.setAttribute('aria-hidden', el.getAttribute('data-vy-keep-hidden'));
    } else {
      el.removeAttribute('aria-hidden');
    }

    el.removeAttribute('data-vy-keep-hidden');
  }
};
