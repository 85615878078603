/*
* Handles injecting html & css related specfically to lightbox embeds
*/
import { getPlaybackURL } from '../utils/config';
import * as EmbedHelpers from '../utils/embed-helpers';
import DataParams from './data-params';
import getPlayerMetadata from '../api/get-player-metadata';

interface Sizing {
  maxHeight: number | null;
  maxWidth: number | null;
  ratio: string;
}

// This code sets up the css and listeners for the on page splash screen & play button
export function init(
  embedScriptElement: HTMLDivElement,
  queryParams: DataParams,
  sizing: Sizing,
): void {
  const lightboxThumbnailUrl = `https://${getPlaybackURL()}/${queryParams.uuid}.jpg`;
  const lightboxString = composeLightboxString(lightboxThumbnailUrl, queryParams, sizing);
  embedScriptElement.insertAdjacentHTML('afterbegin', lightboxString);

  getPlayerMetadata(queryParams.uuid)
    .then((metadata) => {
      const playButton = EmbedHelpers.getElementByClass('play-button', 'button', embedScriptElement);
      if (playButton.length !== 1) {
        return;
      }

      playButton[0].setAttribute('aria-label', 'Play video ' + metadata.name + '. Opens in a modal');
    }, () => {
      // Ignore errors fetching metadata
    });
}

export function applyLightboxPlayButton(
  pbData: { pb: number; pbc: string },
  embedScriptElement: HTMLDivElement,
): void {
  if (pbData.pb !== 1) {
    return;
  }

  const playButton = EmbedHelpers.getElementByClass('play-button', 'button', embedScriptElement);
  if (playButton.length !== 1) {
    return;
  }

  playButton[0].style.display = 'block';
  playButton[0].style.backgroundColor = '#' + pbData.pbc;
}

// --- Private Functions ---
function composeLightboxString(
  lightboxThumbnailUrl: string,
  queryParams: DataParams,
  sizing: Sizing,
): string {
  return (
    '<div class="vidyard-lightbox-thumbnail vidyard-lightbox-' + queryParams.uuid + '"' +
      (sizing.maxWidth ? ' style="max-width: ' + sizing.maxWidth + 'px;"': '') +
    '>' +
    '<div class="vidyard-lightbox-centering" ' +
      'style="padding-bottom: ' + sizing.ratio + '%;">' +
        '<img class="vidyard-lightbox-image" src="' + lightboxThumbnailUrl + '" alt="video thumbnail" />' +
        '<div type="button" role="button" class="play-button" title="Play video" data-version="1" tabindex="0">' +
          '<div class="play-button-size"></div>' +
          '<div class="arrow-size">' +
            '<div class="arrow-size-ratio"></div>' +
            '<div class="arrow"></div>' +
          '</div>' +
        '</div>' +
      '</div>' +
    '</div>'
  );
}
