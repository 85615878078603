import Promise from '../utils/promise';
import { logger } from '../api/debug';
import { calcAspectRatio, addListener, toggleClass } from '../utils/embed-helpers';
import { createIframe, SizingMap } from '../models/markup-injector';
import { getStyle } from '../utils/jsonp';
import { getPlaybackURL, PLAYLIST_WIDTH } from '../utils/config';
import DataParams from '../models/data-params';
import VidyardPlayer from '../models/vidyard-player';
import { loadServerThumbnail } from '../models/placeholder';

interface InjectInlineElementsArgs {
  dataParams: DataParams;
  player: VidyardPlayer;
}

const injectInlineElements = ({ dataParams, player }: InjectInlineElementsArgs): void => {
  const log = logger.setLevel(dataParams.uuid);
  log(`injecting inline embed`);

  const updateAspectRatio = (): Promise<string | boolean> => {
    // Get thumbnail from server and calculate aspect ratio if the placeholder is different (edge case)
    if (player.placeholder.src !== `//${getPlaybackURL()}/${player.uuid}.jpg`) {
      return loadServerThumbnail(player.uuid)
        .then(calcAspectRatio)
        .catch(() => false);
    }
    return Promise.resolve(false);
  };

  const sizing: SizingMap = {
    maxHeight: dataParams.height ? parseInt(dataParams.height, 10).toString() : null,
    maxWidth: dataParams.width ? parseInt(dataParams.width, 10).toString() : null,
    ratio: calcAspectRatio(player.placeholder),
  };

  // Create and attach the players iframe & containing <div>
  player.container.innerHTML = createIframe(dataParams, sizing);

  const iframe: HTMLIFrameElement = player.container.getElementsByTagName('iframe')[0];
  player.iframe = iframe;

  const iframeLoadedPromise: Promise<null> = new Promise(
    (res): void => {
      addListener('load', 'onload', res, iframe);
    },
  );

  const playerReadyPromise: Promise<null> = new Promise(
    (res): void => {
      player.on('ready', res);
    },
  );

  player.on(
    'sidePlaylistOpen',
    (): void => {
      handleResize();
    },
  );

  // The iframe will finish first, usually.
  Promise.race([iframeLoadedPromise, playerReadyPromise]).then(
    (): void => {
      log(`player or iFrame is ready`);
      // If the placeholder image is different than the server thumbnail, update the aspect ratio
      // to be consistent with the server thumbnail (edge case)
      updateAspectRatio().then((newAspectRatio) => {
        if (newAspectRatio) {
          iframe.parentElement.parentElement.style.paddingBottom = `${newAspectRatio}%`;
        }
      });

      const innerContainer = player.container.getElementsByClassName(`vidyard-inner-container-${player.uuid}`)[0];

      // Copy the place holder image to the iframe's parent element
      player.placeholder.parentElement.removeChild(player.placeholder);
      toggleClass(player.placeholder, 'inserted', true);
      innerContainer.appendChild(player.placeholder);
      // This inserts the iframe into the document flow
      iframe.parentElement.parentElement.style.position = 'relative';
      // Be sure that the iframe is fully visible
      iframe.style.opacity = '1';
    },
  );

  iframeLoadedPromise.then(() => {
    // Hide the placeholder image only after the iframe has loaded
    player.placeholder.style.display = 'none';
    iframe.parentElement.parentElement.style.backgroundColor = 'transparent';
  });

  // Set the iframe title to match the video title after player metadata is ready
  player.on('metadata', function updateInlineIframeTitle(args: any[]) {
    const metadata = args[0];
    iframe.title = metadata.name;
    player.off('metadata', updateInlineIframeTitle);
  });

  function handleResize(): void {
    // The breakpoint is the side playlist width, doubled. If the available space is less, we need to hide the side playlist.
    const aboveBreakpoint: boolean = player.container.clientWidth >= PLAYLIST_WIDTH * 2;
    toggleClass(player.container, 'playlist-open', aboveBreakpoint);
  }

  log(`getStyle sent`);
  getStyle(player.uuid).then(
    // pl is the "playlist always open" setting from the Dashboard.
    (data: { pl: number }): void => {
      log(`getStyle received: ${JSON.stringify(data)}`);
      const playlistAlwaysOpen: boolean =
        (data.pl === 1 && dataParams.playlist_always_open !== '0') ||
        dataParams.playlist_always_open === '1';
      if (playlistAlwaysOpen) {
        player.iframe.parentElement.setAttribute('data-pl', 'true');
        addListener('resize', 'onresize', handleResize);
        handleResize();
      } else {
        player.iframe.parentElement.setAttribute('data-pl', 'false');
      }
    },
  ).catch(e => {
      log('getStyle failed, likely a network error');
  });
};

export default injectInlineElements;
