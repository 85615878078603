/*
* Provides a function which when instantiated polls document.cookie for new cookie
* values which are then communicated the all player iframes from the same org
*/
import Player from './vidyard-player';
import { getPlaybackURL } from '../utils/config';
import { indexOfArray, log, addListener } from '../utils/embed-helpers';
import { send } from '../utils/frame-messenger';

export interface TmpOrg {
  eloqua?: string; // @TODO: verify this type
  eloquaFirstPartyDomain?: string; // @TODO: verify this type
  marketo?: string;
  orgId: number;
  pardot?: string; // @TODO: verify this type
}

export interface Org {
  foundIntegrations: {};
  id: number;
  integrations: TmpOrg;
  players: Player[];
}

// @TODO convert IntegrationsWatcher to a class to avoid this duplication
export interface IntegrationsWatcherInstance {
  updatePlayer: (player: Player) => void;
  safelyCommunicateIntegration: (
    player: Player,
    integration: Integrations,
    externalIdentifier: string,
  ) => void;
  addKnownVisitor: (integrationName: Integrations, visitorData: any, org: Org) => void;
  getCookie: (integration: Integrations, organization?: Org) => string | any;
}

// convert array type to union of values
type Integrations = typeof messageNames[number];
type Cookies = typeof cookieNames[number];

// All possible cookie names we would look for, the message names are different from the cookie keys
const cookieNames = [
  'pardot' as 'pardot',
  'hubspotutk' as 'hubspotutk',
  '_mkto_trk' as '_mkto_trk',
  'vy_dreamforce' as 'vy_dreamforce',
  'eloqua' as 'eloqua',
];

const messageNames = [
  'pardot' as 'pardot',
  'hubspot' as 'hubspot',
  'marketo' as 'marketo',
  'dreamforce' as 'dreamforce',
  'eloqua' as 'eloqua',
];

export default function IntegrationsWatcher() {
  let cookieCheckInterval = null;
  let loadedEloquaScript = false;
  const organizations: { [key: string]: Org } = {};

  // --- Public Functions ---
  this.updatePlayer = (player: Player): void => {
    // Don't continue if we don't have an iframe and integration information
    if (!player._tmpOrg || !player.iframe) {
      return;
    }

    const { orgId } = player._tmpOrg;

    if (!organizations[orgId]) {
      organizations[orgId] = {
        foundIntegrations: {},
        id: orgId,
        // All integrations supported by this organization
        integrations: player._tmpOrg,
        // Store of cookie data found keyed by integration name
        // Each integration has an externalIdentifier set with the tracking cookie/uuid if found
        // as well as an object with which players have been sent the associateVisitor event
        // e.g. {integration: {externalIdentifier: 'cookie_value', sentPlayers: ['player1.uuid']}}
        // In DOM players associated with this organization
        players: [],
      };
    }

    player.org = organizations[orgId];

    // Check if this player has already been added to the organizations list
    // if so, do nothing
    for (const currentPlayer of organizations[orgId].players) {
      if (currentPlayer.iframe === player.iframe) {
        return;
      }
    }

    organizations[orgId].players.push(player);

    const { integrations: { eloqua, eloquaFirstPartyDomain } } = player.org;
    if (eloqua) { // Only load the eloqua script if the player needs it
      // Only load the tracking script if the user has given GDPR consent
      if (player.status !== null && player.status.consent) {
        // Note: This function only does something the first time it is called
        loadEloquaTrackingScript(eloqua, eloquaFirstPartyDomain);
      } else {
        player.on('status', function handleStatus() {
          if (player.status.consent) {
            loadEloquaTrackingScript(eloqua, eloquaFirstPartyDomain);
            player.off('status', handleStatus);
          }
        });
      }
    }

    // Loop over all integrations setup with this org & communicate them to the player
    // making sure to only send the event once per player & integration
    checkForIntegrations();
  };

  // Use function chaining to ensure a Player is only messaged about an integration once
  // It is expected that `updatePlayer` is called on a player before this
  this.safelyCommunicateIntegration = (
    player: Player,
    integration: Integrations,
    externalIdentifier: string,
  ): void => {
    if (
      player.org !== undefined &&
      externalIdentifier !== undefined &&
      externalIdentifier !== null &&
      !haveSentIntegrationToPlayer(player, integration) &&
      communicateIntegration(player, integration, externalIdentifier)
    ) {
      player.org = setIntegrationSent(player.org, integration, player.uuid);
    }
  };

  this.addKnownVisitor = (integrationName: Integrations, visitorData: any, org: Org): void => {
    if (!org) {
      return;
    }

    // Go through and message all active players
    for (const player of org.players) {
      communicateKnownVisitor(player, integrationName, visitorData);
    }
  };

  // @TODO: switch the return type form any to unknown once we upgrade TS
  // Loop through all available cookies and return the value for cookieName if found
  this.getCookie = (integration: Integrations, organization?: Org): string | any => {
    const cookies = document.cookie.split(';');

    if (integration === 'eloqua' && typeof window.GetElqCustomerGUID === 'function') {
      // GetElqCustomerGUID is put on the DOM by Eloqua tracking scripts
      return window.GetElqCustomerGUID();
    }

    const cookieName = alterDynamicCookieName(
      cookieNames[messageNames.indexOf(integration)],
      organization,
    );

    // Loop through all cookies looking for supplied cookie name
    for (const currentCookie of cookies) {
      const equalIndex = currentCookie.indexOf('=');
      const foundName = currentCookie.substr(0, equalIndex).replace(/^\s+|\s+$/g, '');
      const foundId = currentCookie.substr(equalIndex + 1);

      if (foundName === cookieName) {
        return decodeURIComponent(foundId);
      }
    }
  };

  // --- Private Functions ---
  // Return true if the integration cookie event has already been sent to this Player
  const haveSentIntegrationToPlayer = (player: Player, integration: Integrations): boolean => {
    return (
      player.org &&
      player.org.foundIntegrations &&
      player.org.foundIntegrations[integration] &&
      player.org.foundIntegrations[integration].sentPlayers &&
      indexOfArray(player.uuid, player.org.foundIntegrations[integration].sentPlayers) !== -1
    );
  };

  // Initialize object to track integration cookies and
  // players which have been sent the cookie already
  const setupFoundIntegration = (org: Org, integration: Integrations): Org => {
    if (!org.foundIntegrations[integration]) {
      org.foundIntegrations[integration] = {
        externalIdentifier: null,
        sentPlayers: [],
      };
    }
    return org;
  };

  const setIntegrationSent = (
    org: Org,
    integration: Integrations,
    playerUuid: Player['uuid'],
  ): Org => {
    org = setupFoundIntegration(org, integration);
    org.foundIntegrations[integration].sentPlayers.push(playerUuid);
    return org;
  };

  const setIntegrationIdentifier = (
    org: Org,
    integration: Integrations,
    externalIdentifier: string,
  ): Org => {
    org = setupFoundIntegration(org, integration);
    org.foundIntegrations[integration].externalIdentifier = externalIdentifier;
    return org;
  };

  // Send event to Player with integration external identifier
  const communicateIntegration = (
    player: Player,
    integration: Integrations,
    externalIdentifier: string,
  ): boolean => {
    if (!player.ready()) {
      return false;
    }

    // Player iframe already exists, message it about the integration
    const message = {
      data: { type: integration, value: externalIdentifier },
      event: 'associateVisitor',
      uuid: player.uuid,
    };

    log('IntegrationsWatcher.communicateIntegration ' + message, 'debug');
    send(message, `https://${getPlaybackURL()}`, player.iframe);
    return true;
  };

  // Go through and message all active players on DOM which haven't already been sent
  const messagePlayersFoundIntegration = (
    players: Player[],
    integration: Integrations,
    externalIdentifier: string,
  ): void => {
    if (players === undefined || externalIdentifier === undefined || externalIdentifier === null) {
      return;
    }

    for (const player of players) {
      this.safelyCommunicateIntegration(player, integration, externalIdentifier);
    }
  };

  const sendKnownVisitor = (player: Player, leadType: Integrations, leadData: any): void => {
    const message = {
      data: { type: leadType, value: leadData },
      event: 'identifyVisitor',
      uuid: player.uuid,
    };

    send(message, `https://${getPlaybackURL()}`, player.iframe);
  };

  const communicateKnownVisitor = (player: Player, leadType: Integrations, leadData: any): void => {
    if (!player.ready()) {
      // Setup callback if Player not ready yet
      player.on('ready', (): void => {
        sendKnownVisitor(player, leadType, leadData);
      });
    } else {
      // Player iframe already exists, message it about the lead
      sendKnownVisitor(player, leadType, leadData);
    }
  };

  // Find any integration trackers on the page and send to each player that hasn't received yet
  const checkForIntegrations = (): void => {
    // Go through all possible integration
    for (const integration of messageNames) {
      // Loop through all orgs and see if they care about this integration
      for (const orgKey in organizations) {
        if (organizations.hasOwnProperty(orgKey)) {
          let currentOrganization = organizations[orgKey];

          // Do nothing if the org doesn't have this integration
          if (!currentOrganization.integrations[integration]) {
            continue;
          }

          // Check if integration tracker is present on the page
          let cookieValue = this.getCookie(integration, currentOrganization);

          if (!cookieValue) {
            continue;
          }

          cookieValue = parseCookieValue(integration, cookieValue, currentOrganization);
          // parseCookieValue can return null if Marketo cookie doesn't match Munchkin ID
          if (!cookieValue) {
            continue;
          }

          currentOrganization = setIntegrationIdentifier(
            currentOrganization,
            integration,
            cookieValue,
          );

          messagePlayersFoundIntegration(currentOrganization.players, integration, cookieValue);
        }
      }
    }
  };

  // Pardot uses a dynamic cookie name, set it up here
  const alterDynamicCookieName = (cookieName: Cookies, organization?: Org): string | Cookies => {
    if (cookieName === 'pardot' && organization && organization.integrations.pardot) {
      return 'visitor_id' + organization.integrations.pardot;
    }

    return cookieName;
  };

  const parseCookieValue = (
    msgName: Integrations,
    cookieValue: string,
    org: Org,
  ): null | string => {
    if (msgName === 'marketo') {
      // Make sure this cookie is actually for this players organization
      if (
        !org.integrations.marketo ||
        cookieValue.toLowerCase().indexOf(org.integrations.marketo.toLowerCase()) === -1
      ) {
        return null;
      }

      cookieValue = encodeURIComponent(cookieValue);
    }

    return cookieValue;
  };

  const loadEloquaTrackingScript = (
    siteId: TmpOrg['eloqua'],
    firstPartyDomain: TmpOrg['eloquaFirstPartyDomain'],
  ): void => {
    if (loadedEloquaScript) {
      return;
    }
    loadedEloquaScript = true;

    const createEloquaScriptNode = (): void => {
      // Only set up Eloqua tracking script once (including v3 & hubs)
      if (document.getElementById('vidyard-eloqua-include')) {
        return;
      }

      window._elqQ = window._elqQ || [];
      window._elqQ.push(['elqSetSiteId', siteId]);
      if (firstPartyDomain) {
        window._elqQ.push(['elqUseFirstPartyCookie', firstPartyDomain]);
      }
      window._elqQ.push(['elqTrackPageView']);
      window._elqQ.push(['elqGetCustomerGUID']);

      const eloquaScript = document.createElement('script');
      eloquaScript.id = 'vidyard-eloqua-include';
      eloquaScript.type = 'text/javascript';
      eloquaScript.async = true;
      eloquaScript.src = 'https://img.en25.com/i/elqCfg.min.js';

      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(eloquaScript, firstScript);
    };

    if (document.readyState === 'complete') {
      createEloquaScriptNode();
    } else {
      addListener('DOMContentLoaded', 'onload', createEloquaScriptNode);
    }
  };

  cookieCheckInterval = setInterval((): void => {
    // We check for new cookies every second in case the player is on a page with
    // tracking scripts that will be dynamically adding new cookies at some point
    checkForIntegrations();
  }, 1000);
}
